.shape {
    z-index: 2;
} 
.shadow {
    z-index: 1;
}

.masked-component-container {
    &__image-image {
        position: relative;
        width: 100%;
        height: 100%;
        @include media-breakpoint-down(lg) {
            width: 85%;
        }
        @include media-breakpoint-down(md) {
            width: 600px;
        }
        @include media-breakpoint-down(sm) {
            width: 470px;
        }
        @media (max-width: 500px) {
            width: 340px;
        }
        .masked-image {
            &__shape {
                position: absolute;
                left: 0%;
                bottom: 0;
                width: 330px;
                height: 330px;
                @media (min-width: 2000px) {
                    left: 10%;
                    bottom: -60px;
                    width: 380px;
                    height: 380px;
                }
                @media (max-width: 1400px) {
                    width: 250px;
                    height: 250px;
                }
                @media (max-width: 1199px) {
                    width: 330px;
                    height: 330px;
                }
                @include media-breakpoint-down(md) {
                    width: 250px;
                    height: 250px;
                }
                @include media-breakpoint-down(sm) {
                    width: 165px;
                    height: 165px;
                }
                @media (max-width: 500px) {
                    width: 100%;
                    height: 100%;
                    max-width: 132px;
                    max-height: 132px;
                }
                @media (max-width: 380px) {
                    max-width: 40%;
                    max-height: 65%;
                }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    @media (max-width: 500px) {
                        aspect-ratio: 1/1;
                    }
                }
            }
    
            &__shadow {
                position: absolute;
                left: 20%;
                top: 0;
                width: 660px;
                height: 466px;
                @media (min-width: 2000px) {
                    left: 30%;
                    top: -20px;
                    width: 760px;
                    height: 530px;
                }
                @media (min-width: 1400px) and (max-width: 1600px) {
                    left: 12%;
                }
                @media (max-width: 1400px) {
                    width: 495px;
                    height: 350px;
                }
                @media (max-width: 1199px) {
                    width: 660px;
                    height: 466px;
                }
                @include media-breakpoint-down(md) {
                    width: 495px;
                    height: 350px;
                }
                @include media-breakpoint-down(sm) {
                    width: 330px;
                    height: 233px;
                }
                @media (max-width: 500px) {
                    width: 100%;
                    height: 100%;
                    max-width: 264px;
                    max-height: 186px;
                }
                @media (max-width: 380px) {
                    max-width: 80%;
                    max-height: 90%;
                }
                img, video {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    @media (max-width: 380px) {
                        -webkit-mask-position: right !important;
                    }
                }
            }
            
        }
    }

    &__image-color {
        position: relative;
        width: auto;
        &.hero {
            @include media-breakpoint-down(sm) {
                width: 420px;
            }
            @media (max-width: 500px) {
                width: 340px;
            }
            @media (max-width: 380px) {
                width: 100%;
                max-width: 320px;
            }
        }
        .masked-image {
            &__shape {
                width: 650px;
                height: 410px;
                position: relative;
                @media (min-width: 2000px) {
                    width: 750px;
                    height: 510px;
                }
                @media (max-width: 1400px) {
                    width: 580px;
                    height: 370px;
                }
                @include media-breakpoint-down(md) {
                    width: 540px;
                    height: 340px;
                }
                @include media-breakpoint-down(sm) {
                    width: 365px;
                    height: 230px;
                }
                @media (max-width: 500px) {
                    width: 300px;
                    height: 190px;
                }
                @media (max-width: 380px) {
                    width: 85%;
                    height: 100%;
                    max-width: 300px;
                    max-height: 190px;
                }

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }

                // Two Column Image Headline Intro section adjustments
                &.section {
                    width: 500px;
                    height: 500px;
                    bottom: 45px;

                    @media (max-width: 1400px) {
                        width: 450px;
                        height: 450px;
                    }
                    @media (max-width: 1330px) {
                        width: 400px;
                        height: 400px;
                    }
                    @include media-breakpoint-down(lg) {
                        width: 350px;
                        height: 350px;
                    }
                    @include media-breakpoint-down(md) {
                        width: 300px;
                        height: 300px;
                        top: 0;
                        left: 0;
                    }
                    @include media-breakpoint-down(sm) {
                        width: 250px;
                        height: 250px;
                    }
                }

                &.text-align-left {
                    right: 90px;
                }
            }
        }
        .masked-div {
            &__shadow {
                position: absolute;
                width: 650px;
                height: 410px;
                left: 90px;
                top: 80px;
                display: flex;
                justify-content: start;
                align-items: start;
                @media (min-width: 2000px) {
                    width: 750px;
                    height: 510px;
                }
                @media (max-width: 1400px) {
                    width: 580px;
                    height: 370px;
                    left: 80px;
                    top: 60px;
                }
                @include media-breakpoint-down(md) {
                    width: 540px;
                    height: 340px;
                }
                @include media-breakpoint-down(sm) {
                    width: 365px;
                    height: 230px;
                    left: 55px;
                    top: 35px;
                }
                @media (max-width: 500px) {
                    width: 300px;
                    height: 190px;
                    left: 40px;
                    top: 30px;
                }
                @media (max-width: 380px) {
                    width: 85%;
                    height: 100%;
                    max-width: 300px;
                    max-height: 190px;
                    top: 15px;
                }
                
                // Two Column Image Headline Intro section adjustments
                &.section {
                    width: 500px;
                    height: 500px;
                    top: 45px;

                    @media (max-width: 1400px) {
                        width: 450px;
                        height: 450px;
                    }
                    @media (max-width: 1350px) {
                        width: 400px;
                        height: 400px;
                    }
                    @include media-breakpoint-down(lg) {
                        width: 350px;
                        height: 350px;
                    }
                    @include media-breakpoint-down(md) {
                        width: 300px;
                        height: 300px;
                    }
                    @include media-breakpoint-down(sm) {
                        width: 250px;
                        height: 250px;
                    }
                }

                &.text-align-left {
                    @include media-breakpoint-up(md) {
                        left: 0;
                        right: 90px;
                    }
                    @include media-breakpoint-down(md) {
                        left: 60px;
                    }
                }

                &__content {
                    height: 100%;
                    width: 100%;
                
                &.light-green {
                    background-color: $color-light-green;
                }
                &.green {
                    background-color: $color-green;
                }
                &.peach {
                    background-color: $color-peach;
                }
                &.coral {
                    background-color: $color-coral;
                }
                &.stone {
                    background-color: $color-stone;
                }
                &.sand {
                    background-color: $color-sand;
                }
                &.bright-blue {
                    background-color: $color-bright-blue;
                }
                &.aareon-blue {
                    background-color: $color-aareon-blue;
                }
            }
            }
        }
    }
}


        