.m-team {
    margin: 80px 0 100px;
    @include media-breakpoint-down(sm) {
        margin: 80px 0 50px;
    }
    &__inner {
        &__title {
            text-align: center;
            margin: 80px 0;
            @include media-breakpoint-down(sm) {
                text-align: left;
                margin: 20px 0;
                padding: 0 10px;
            }
        }
        .wrapper {
            margin-bottom: 50px;
            h4 {
                margin-bottom: 10px;
            }
            p {
                font-size: 20px;
            }
            .image-wrapper {
                margin-bottom: 20px;
                img {
                   width: 100%;
                   max-width: 400px;
                   height: 290px;
                   object-fit: cover;
                   @media (min-width: 2000px) {
                    max-width: 100%;
                    height: 390px;
                   }
                   @include media-breakpoint-down(md) {
                    max-width: 100%;
                   }
                   @include media-breakpoint-down(sm) {
                    height: 245px;
                   }
                }
            }
        }
    }
}

.m-team-bio {
    padding: 65px 0 100px;
    @include media-breakpoint-down(sm) {
        padding: 30px 0 92px;
    }
    &__inner {
        max-width: 836px;
        margin-left: auto;
        margin-right: auto;
        &__title {
            margin-bottom: 30px;
            text-align: center;
            @include media-breakpoint-down(sm) {
                margin-bottom: 20px;
                text-align: left;
            }
        }
        &__subtitle {
            text-align: center;
            @include media-breakpoint-down(sm) {
                text-align: left;
            }
        }
        &__image img{
            width: 100%;
            height: auto;
            margin-top: 26px;
            margin-bottom: 42px;
            @include media-breakpoint-down(sm) {
                margin-top: 20px;
                margin-bottom: 20px;
            }
        }
    }
}