.m-testimonials {
    overflow: hidden;

    &__content {
        width: 60%;
        display: grid;
        align-content: space-between;
        padding-right: 20px;

        p{
            margin: 0;
            color: $color-black;
            display: -webkit-box;
            -webkit-line-clamp: 7;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &__image {
        position: absolute;
        width: 40%;
        height: 100%;
        right: 0;
        top: 0;
        z-index: -1;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .overlay {
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
        background: linear-gradient(90deg, #F7F3F0 60%, rgba(247, 243, 240, 0.00) 90%);
    }

    @include media-breakpoint-down(lg) {
        &__content {
            p{
                font-size: rem(18px);
		        line-height: rem(24px);
                -webkit-line-clamp: inherit;
            }

            .author{
                margin-top: 20px;
            }
        }
    }

    @include media-breakpoint-down(md) {

        &__content {
            width: 100%;
            padding-right: 0;

            p{
                font-size: rem(16px);
		        line-height: rem(22px);
            }

            .author{
                margin-top: 20px;
            }
        }

        &__image {
            order: 1;
            width: 100%;
            position: initial;
            height: 300px;
        }

        .overlay {
            background: linear-gradient(180deg, rgba(247, 243, 240, 0.00) 200px, #F7F3F0 300px);
        }
    }
}

.m-testimonials-slider {
    padding: 40px 0 240px;

    .swiper-container{
        overflow: visible;
    }

    .swiper-slide{
        display: flex;
        cursor: grab;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: $color-sand;
        border-radius: 24px;
        min-height: 354px;
        overflow: hidden;
        @media (min-width: 2000px) {
            min-height: 454px;
            .swiper-slide-spacer{
                padding: 60px;
            }
        }
    }

    .swiper-slide-spacer{
        display: flex;
        position: relative;
        padding: 35px 40px;
        z-index: 1;
    }

    .swiper-pagination.custom {
        display: flex;
        justify-content: space-evenly;
        align-items: flex-start;
        bottom: -180px;
        padding: 40px;

        img{
            display: block;
        }

        &::before{
            content: '';
            height: 2px;
            width: calc(100% - 80px);
            display: block;
            position: absolute;
            left: 40px;
            top: 40px;
            border-radius: 2px;
            background-color: rgba($color: #000000, $alpha: 0.1)
        }
    }

    .swiper-slide .pagination-logo {
        display: none;
    }

    .swiper-pagination.custom .swiper-pagination-bullet {
        margin: 0;
        width: 100%;
        height: auto;
        background: transparent;
        border-radius: 0;
        cursor: pointer;
        opacity: 0.4;
        position: relative;
        padding-top: 30px;
        transition: all 0.3s ease;
        min-height: 100px;
        display: flex;
        align-items: center;
        transition: all .4s ease;
        
        img{
            filter: grayscale(1);
            width: 100%;
            height: auto;
            max-width: 100px;
            max-height: 60px;
            margin: auto;
        }

        &::before {
            content: '';
            height: 2px;
            width: 0;
            position: absolute;
            top: 0;
            border-radius: 2px;
            background-color: $color-black;
        }

        &:hover{
            opacity: 1;
        }
    }    

    .swiper-pagination.custom .swiper-pagination-bullet.swiper-pagination-bullet-active{
        opacity: 1;

        img {
            filter: none;
        }

        &::before {
            content: '';
            width: 100%;
            transition: all .4s ease;
            transform-origin: center center;
        }
    }

    @include media-breakpoint-down(md) {
        padding: 30px 0 180px;
        
        .swiper-slide {
            display: grid;
        }

        .swiper-slide-spacer{
            order: 2;
            padding: 30px;
        }

        .swiper-pagination.custom {
            padding: 0;
            bottom: -90px;

            &::before {
                display: none;
            }
        }

        .swiper-pagination.custom .swiper-pagination-bullet {
            padding: 0;
            min-height: 60px;

            img{
                max-width: 70px;
                max-height: 60px;
            }
        }

        .swiper-pagination.custom .swiper-pagination-bullet.swiper-pagination-bullet-active {
            &::before {
                display: none;
            }
        }
    }
}

.m-testimonials-quote {
    padding: 40px 0 50px;

    @media (min-width: 2000px) {
        padding: 40px 0 100px;
    }
    
    .m-testimonials-quote-wrapper{
        display: flex;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: $color-sand;
        border-radius: 24px;
        min-height: 354px;
        position: relative;
        overflow: hidden;
        @media (min-width: 2000px) {
            min-height: 454px;
        }
    }

    .m-testimonials__content {
        z-index: 2;
        padding: 35px 40px;
        @media (min-width: 2000px) {
            padding: 60px;
        }

        p{
            -webkit-line-clamp: 4;
        }

        .logo{
            margin-bottom: 15px;
            max-width: 100px;
            max-height: 60px;
        }
    }

   .m-testimonials__image {
        z-index: 0;
    }
    
    .overlay {
        z-index: 1;
    }

    @include media-breakpoint-down(md) {
        padding: 30px 0 60px;
        
        .m-testimonials-quote-wrapper{
            display: grid;
        }

        .m-testimonials__content {
            order: 2;
            width: 100%;
            padding-right: 0;
            padding: 30px;

            p{
                font-size: rem(16px);
		        line-height: rem(22px);
            }

            .logo{
                max-width: 70px;
                max-height: 60px;
            }

            .author{
                margin-top: 20px;
            }
        }

        .m-testimonials__image {
            order: 1;
            width: 100%;
            position: initial;
            height: 300px;
        }

        .overlay {
            background: linear-gradient(180deg, rgba(247, 243, 240, 0.00) 200px, #F7F3F0 300px);
        }
    }
}