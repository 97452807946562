.subscribe-link {
    margin-top: $link-margin-top;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    @include media-breakpoint-down(sm) {
        gap: 3px;
    }
    .subscribe-link-field {
        border-radius: 200px;
        border: 1px solid var(--Black, #081326);
        height: 54px;
        width: 60%;
        min-width: 250px;
        align-items: center;
        padding: 16px 24px;
        flex-shrink: 0;
        margin-right: 10px;
        @include media-breakpoint-down(sm) {
            height: 43px;
            min-width: 180px;
            font-size: 15px;
            padding: 12px 14px;
        }
    }
    .subscribe-link-button{
        background: $color-bright-blue;
        color: $color-white;
        border-radius: 200px;
        padding: 16px 28px;
        border: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 84px;
        height: 54px;
        &:hover {
            color: $color-white;
            background-color: $color-btn-hover;
        }
        @include media-breakpoint-down(md) {
            font-size: rem(16px);
            line-height: rem(19px);
            padding: 14px 24px;
        }
        @include media-breakpoint-down(sm) {
            width: 63px;
            height: 43px;
        }
    }
}