.m-two-col-image-links {
    padding-top: 100px;
    padding-bottom: 70px;
    @include media-breakpoint-down(sm) {
        padding-top: 30px;
        padding-bottom: 40px;
    }
    
    &__inner {
        display: block;
        .col{
            padding-bottom: 200px;
        }
        .col:first-of-type{
            padding-right: 130px;
        }
        .col:last-of-type{
            padding-left: 130px;
            &::before{
                content: '';
                width: 2px;
                height: 100%;
                background-color: #E6E7E9;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
        @include media-breakpoint-down(lg) {
            .col{
                padding-bottom: 100px;
            }
            .col:first-of-type{
                padding-right: 60px;
            }
            .col:last-of-type{
                padding-left: 60px;
            }
        }
        @include media-breakpoint-down(sm) {
            display: none;
            .col:first-of-type{
                padding-right: 25px;
            }
            .col:last-of-type{
                padding-left: 25px;
                &::before{
                    display: none;
                }
            }
        }
    }

    &__inner-mobile {
        display: none;
        
        @include media-breakpoint-down(sm) {
            display: block;
        }
	}

    &__intro {
        @include media-breakpoint-down(sm) {
            margin-bottom: 60px;
            &:last-of-type{
                margin-bottom: 0;
            }
        }
    }

    &__image {
        display: flex;
        margin-bottom: 40px;

        @include media-breakpoint-down(sm) {
            margin-bottom: 30px;
        }

        img{
            width: 100%;
            height: auto;
        }
    }

    &__links {
        margin-top: 40px;
        
        h3 {
            margin-bottom: 20px;
            font-size: rem(32px);
		    line-height: rem(44px);
            font-family: 'BasisGrotesquePro-Bold';
        }
    }

    &__wrapper {
        margin-bottom: 40px;
        &:last-of-type{
            margin-bottom: 0;
        }
        @include media-breakpoint-down(sm) {
            margin-top: 30px;
            margin-bottom: 0;
        }

        .link-large {
            margin: 0;
            padding: 12px 0;
            img {
                margin-left: 10px;
                width: 16px;
                height: 16px;
            }
            @include media-breakpoint-down(sm) {
                padding: 20px 0;
                a{
                    font-size: rem(20px);
		            line-height: rem(24px);
                }
            }
        }
    }

    &__title {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 32px;
        h3{
            margin-bottom: 0;
            font-size: rem(24px);
            line-height: rem(26px);
            font-family: 'BasisGrotesquePro-Medium';
        }
        .link{
            margin: 0;
        }
    }

    &__link-box {
        padding: 24px;
        margin-bottom: 8px;
        border-radius: 16px;
        background: rgba(8, 109, 251, 0.10);
        .link{
            margin-top: 0;
            margin-bottom: 10px;
        }
    }
}