$base-font-size: 16px;

@font-face {
    font-family: 'BasisGrotesquePro-Regular';
    src: url('BasisGrotesquePro-Regular.woff2') format('woff2'),
         url('BasisGrotesquePro-Regular.woff') format('woff'),
         url('BasisGrotesquePro-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'BasisGrotesquePro-Medium';
    src: url('BasisGrotesquePro-Medium.woff2') format('woff2'),
         url('BasisGrotesquePro-Medium.woff') format('woff'),
         url('BasisGrotesquePro-Medium.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'BasisGrotesquePro-Bold';
    src: url('BasisGrotesquePro-Bold.woff2') format('woff2'),
         url('BasisGrotesquePro-Bold.woff') format('woff'),
         url('BasisGrotesquePro-Bold.ttf') format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: "Canela-Regular";
    src: url('Canela-Regular.woff2') format('woff2'),
		 url('Canela-Regular.woff') format('woff'),
         url("Canela-Regular.otf") format("opentype");
    font-weight: 400;
}
