.m-customers {
	@keyframes scrolling-customer-logos {
		from {
			transform: translateX(0);
		}
		to {
			transform: translateX(-100% );
		}
	}

	position: relative;
	padding-top: 74px;
	padding-bottom: 74px;
	overflow-x: hidden;
	background-color: $color-stone;

	&__inner{
		display: flex;
		justify-content: center;
		padding-bottom: 60px;

		h2{
			text-align: center;
            font-family: 'Canela-Regular';
            font-size: rem(32px);
            line-height: rem(34px);
            letter-spacing: -0.64px;
            margin-bottom: 0;
            z-index: 2;
		}
	}

	&__logos{
		display: flex;
		flex-direction: row;
		width: 100%;
		
		.customer-logos-row{
			display: flex;
			flex-direction: row;
			align-items: center;
			animation: 30s scrolling-customer-logos infinite linear; // Adjust number to increase or decrease speed of animation
			animation-fill-mode: backwards;
			transition: width 0.3s ease; 

			.customer-logos-wrapper{
				margin: 0 30px;

				.customer-logo{
					text-align: center;
					width: 160px;
					height: auto;
					margin: auto;
					
					img{
						width: auto;
						height: auto;
						max-height: 58px;
						max-width: 145px;
                        filter: grayscale(1);
					}
				}
			}
		}
	}

    &::before {
        content: '';
        width: 120px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: linear-gradient(90deg, #EBE3DC 0%, rgba(235, 227, 220, 0.00) 100%);
        display: flex;
        z-index: 1;
    }

    &::after {
        content: '';
        width: 120px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        background: linear-gradient(270deg, #EBE3DC 0%, rgba(235, 227, 220, 0.00) 100%);
        display: flex;
        z-index: 1;
    }

	@include media-breakpoint-down(md) {
		padding-top: 46px;
	    padding-bottom: 46px;

		&__inner{
			padding-bottom: 30px;
            h2{
                font-size: rem(20px);
                line-height: rem(28px);
                max-width: 200px;
            }
		}

		&__logos{
			.logos-row{
				.logos-wrapper{
					margin: 0 30px;

					.logo{
						width: 100px;
						height: auto;
						margin: auto;

						img{
							max-width: 100px;
						}
					}
				}
			}
		}
	}
}