.m-usps {
    padding: 104px 0px;
    @include media-breakpoint-down(lg) {
        padding: 104px 0px;
    }
    @include media-breakpoint-down(sm) {
        padding: 52px 0px;
    }
    .usps-row {
        justify-content: center;
        &.usps-row-col-2 {
            justify-content: flex-start;
        }
        .usps-col {
            justify-content: center;
            margin: 0;
            @include media-breakpoint-down(lg) {
                padding-right: 15px;
                padding-left: 15px;
            }
            &.usps-col-3, &.usps-col-4 {
                padding-right: 20px;
                padding-left: 20px;
                @include media-breakpoint-down(sm) {
                    margin: auto;
                }
            }
            &.usps-col-2 {
                margin-bottom: 40px;
                @include media-breakpoint-down(md) {
                    margin-bottom: 20px;
                }
            }
        }
    }
}