.dropdown {
    .row {
        padding: 0;
        margin: 0;
    }

    .dropdown-trigger {
        margin-top: $btn-margin-top;
        justify-content: space-between;
        align-items: center;
        width: 300px;
        height: 48px;
        border: 2px solid $color-white-op-2;
        border-radius: 200px;
        cursor: pointer;

        @include media-breakpoint-down(md) {
            height: 38px;
            width: 205px;
        }

        @include media-breakpoint-down(sm) {
            width: 234px;
        }
        
        &--active {
            border: 1px solid $color-white;
            border-radius: 20px 20px 0 0;
            border-bottom: none;

            &.upwards {
                border-radius: 0 0 20px 20px;
                border-bottom: 2px solid $color-white;
                border-top: none;
            }
        }

        .icon-globe {
            padding-left: 18px;
            padding-right: 10px;
        }

        .icon-dropdown {
            padding-right: 20px;
            margin: auto;
        }

        .default-country {
            font-size: 16px;
            color: $color-white;
        }
    }

    .dropdown-button {
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;

        @include media-breakpoint-down(md) {
            height: 38px;
        }

        img {
            width: 24px;
            height: 19px;
        }

        &.transparent {
            background-color: transparent;
            border: 2px solid $color-white-op-2;
        }
    }

    .dropdown-list {
        position: absolute;
        height: 0;
        width: 300px;
        overflow: hidden;
        border-radius: 0 0 20px 20px;
        z-index: 1;
        background-color: $color-aareon-blue;
        
        &--active {
            transition: height .4s ease;
            display: block;
            height: 250px;
            border: 1px solid $color-white;
            border-top: none;
            overflow-y: scroll;

            @include media-breakpoint-down(md) {
                width: 205px;
                height: 238px;
            }
    
            @include media-breakpoint-down(sm) {
                width: 234px;
                height: 220px;
            }

            &.upwards {
                border-radius: 20px 20px 0 0;
                border-top: 1px solid $color-white;
                border-bottom: none;
                position: absolute;
                transform: translateY(-100%);
                top: 20px;
            }

            &.transparent {
                background-color: $color-black;
            }
        }

        .country-list {
            border-top: 1px solid $color-white-op-1;
            padding: 10px 0;
            margin: 0 18px;
            
            &.upwards {
                border-bottom: 1px solid $color-white-op-1;
                border-top: none;
            }
            
            .country-wrapper {
                .country-name {
                    margin: 0 27px;
                    
                    .country {
                        margin: 0;
                        padding: 5px;
                        font-size: 16px;
                        cursor: pointer;
                        color: $color-white;
                        transition: all .2s ease;
                        &:hover{
                            opacity: 0.6;
                        }
                    }
                }
                
                .icon-check {
                    display: none;
                }

                &--active {
                    .icon-check {
                        display: block;
                    }

                    .country-name {
                        margin: 0 7px;
                    }
                }
            }
        }
    }
}