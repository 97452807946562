.bulletlist-ul {
  margin-left: 20px;
  list-style: disc;

  .bulletlist-li {

  }
}

.bulletlist-links-ul {
  margin-left: 30px;
  list-style: disc;

  .bulletlist-links-li {
    color: $color-bright-blue;
    font-size: rem(16px);
    line-height: rem(32px);
    a {
      font-family: 'BasisGrotesquePro-Regular';
      text-decoration-line: underline;
    }
    @include media-breakpoint-down(md) {
      font-size: rem(16px);
      line-height: rem(24px);
    }
  }

  &.links-large {
    .bulletlist-links-li {
      color: $color-bright-blue;
      font-size: rem(20px);
      line-height: rem(36px);
      @include media-breakpoint-down(md) {
        font-size: rem(16px);
        line-height: rem(24px);
      }
      a {
        font-family: 'BasisGrotesquePro-Regular';
        text-decoration-line: underline;
      }
    }
  }
}