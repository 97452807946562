@keyframes scrolling-cards {
    0% { transform: translateX(0); }
    100% { transform: translatex(-2540px); }
}

@keyframes scrolling-cards-reversed {
    0% { transform: translateX(0); }
    100% { transform: translatex(2540px); }
}	

.m-cards {
    padding: 70px 0px;
    &.half-padding {
        padding: 40px 0px;
    }
    @include media-breakpoint-down(lg) {
        padding: 52px 0px;
        &.half-padding {
            padding: 20px 0px;
        }
    }
    h3 {
        font-family: 'Canela-Regular';
        font-size: 40px;
        line-height: 105%;
    }
    .slider-container {
        display: flex;
        justify-content: center;
        @include media-breakpoint-up(lg) {
            .cards-row {
                .card-col-2:first-of-type {
                    padding-left: 0px;
                }
                .card-col-2:last-of-type {
                    padding-right: 0px;
                }
            }
        }
        @media (max-width: 540px) {
            padding-left: 0;
            padding-right: 0;
        }
        > div {
            width: 100%;
        }
    }
    .cards-row {
        // @include media-breakpoint-down(sm) {
        //     width: 100%;
        // }
        .card-col-2 {
            margin-bottom: 20px;
        }
    }
}

.m-cards + .m-cards {
    padding: 0 0px 50px;
    @include media-breakpoint-down(lg) {
        padding: 10px 0px 52px;
    }
}
