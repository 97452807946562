$color-black: #081326;
$color-white: #fff;
$color-grey: #384152;
$color-grey-op-1: #3841521a;
$color-aareon-blue: #051163;
$color-bright-blue: #086DFB;
$color-light-green: #B9E99C;
$color-green: #50B214;
$color-peach: #FFD8CA;
$color-coral: #FF7F62;
$color-stone: #EBE3DC;
$color-sand: #F7F3F0;
$color-line: #E6E7E9;
$color-btn-hover: #0552E8;
$color-white-op-1: rgba(255, 255, 255, 0.1);
$color-white-op-2: rgba(255, 255, 255, 0.2);
$color-white-op-3: rgba(255, 255, 255, 0.3);
$color-white-op-6: rgba(255, 255, 255, 0.6);