.m-footer {
	background-color: $color-black;
	padding: 117px 0 39px 0;
	@include media-breakpoint-down(md) {
		padding: 50px 0;
	}

	.col-sm-6, .col-sm-6, .col-sm-6 {
		position: unset;
	}

	&__category {
		color: $color-white-op-3;
		font-size: 16px;
		@include media-breakpoint-down(sm) {
			font-size: 14px;
		}
	}

	&__nav-wrapper {
		border-bottom: 2px solid $color-white-op-2;
	}
	
	&__nav-list {
		margin-top: 20px;
		padding-bottom: 76px;
		@include media-breakpoint-down(sm) {
			margin-top: 10px;
			padding-bottom: 0;
		}

		ul {
			list-style: none;
			margin-left: 0;
		}

		li {
			margin-top: 15px;
			@include media-breakpoint-down(sm) {
				margin-top: 0;
				margin-bottom: 20px;
			}
			
			a {
				color: $color-white;
				font-size: 20px;
				line-height: 22px;
				text-decoration: none;
				font-family: 'BasisGrotesquePro-Regular';
				&:hover {
					opacity: 0.6;
				}
				@include media-breakpoint-down(sm) {
					font-size: 14px;
				}
			}
		}

		label {
			color: #6B717D;
			font-size: 13px;
			line-height: 16px;
			letter-spacing: -0.26px;
			display: block;
			margin-top: 4px;
		}
	}

	&__info {
		margin-top: 10px;
		@include media-breakpoint-down(sm) {
			display: none;
		}

		p {
			color: $color-white-op-6;
			font-size: 16px;
		}

		.logo {
			display: flex;
			align-items: center;
		}

		.info-links {
			margin-top: 60px;
			.row {
				margin: 0;
			}
		}
	}

	&__info-mobile {
		display: none;
		@include media-breakpoint-down(sm) {
			display: block;
		}

		p {
			color: $color-white-op-6;
			font-size: 13px;
		}

		.row, .col, .col-6 {
			margin: 0;
			padding: 0;
		}

		.logo-links {
			margin-top: 30px;
			li {
				margin-top: 10px;
				a {
					font-size: 13px;
				}
			}
		}

		.aareon {
			margin-left: 20px;
			margin-top: 20px;
		}
	}

	&__nav-info-links {
		ul {
			list-style: none;
			margin-left: 0;
		}
		
		a {
			color: $color-white-op-6;
			font-size: 16px;
			text-decoration: none;
			margin-left: 20px;
			font-family: 'BasisGrotesquePro-Regular';
			&:hover {
				opacity: 0.8;
			}
		}
	}
}
