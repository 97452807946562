.m-news-feed {
	padding: 60px 0;
	@include media-breakpoint-down(md) {
		padding: 30px 0 60px;
	}
	.container {
		padding: 0;
		overflow: hidden;
	}
	&__inner {
		padding-right: 80px;
		padding-left: 80px;
		@include media-breakpoint-down(md) {
			padding-right: 50px;
			   padding-left: 50px;
		}
		@include media-breakpoint-down(sm) {
			padding-right: 20px;
			   padding-left: 20px;
		}
		h3 {
			font-family: 'Canela-Regular';
			font-size: 40px;
			line-height: 42px;
			margin: 0;
		}
	}
	.m-news-card {
		&__inner {
			padding-right: 80px;
			padding-left: 80px;
			@include media-breakpoint-down(md) {
				padding-right: 50px;
   				padding-left: 50px;
			}
			@include media-breakpoint-down(sm) {
				padding-right: 20px;
				padding-left: 20px;
			}
		}
	}
	.load-more {
		padding-top: 50px;
		display: flex;
    	justify-content: center;
		@include media-breakpoint-down(md) {
			padding-top: 0;
		}
	}
}

.m-filter {
	margin-top: 30px;
	margin-bottom: 50px;
	@include media-breakpoint-down(sm) {
		margin-top: 14px;
		margin-bottom: 24px;
	}
	&__inner {
		padding-right: 80px;
		padding-left: 80px;
		overflow-x: scroll;
		scrollbar-width: none; 
		-ms-overflow-style: none; 
		@include media-breakpoint-down(md) {
			padding-right: 50px;
			padding-left: 50px;
		}
		@include media-breakpoint-down(sm) {
			padding-right: 20px;
			padding-left: 20px;
		}

		&::-webkit-scrollbar {
			width: 0;
			height: 0;
		}
	}
	.category-wrapper {
		display: inline-flex;
		gap: 10px;
		@include media-breakpoint-down(md) {
			gap: 8px;
		}
	}
	.category, .category-all {
		margin: 0;
		min-width: 100px;
		padding: 15px 36px;
		font-size: rem(16px);
        line-height: rem(22px);
		color: $color-bright-blue;
		font-family: 'BasisGrotesquePro-Bold';
		background-color: $color-sand;
		border: 2px solid $color-sand;
		&.active{
			border: 2px solid $color-bright-blue;
		}
		@include media-breakpoint-down(md) {
			height: 40px;
			font-size: rem(12px);
			line-height: rem(12px);
			padding: 6px 16px;
		}
	}
}

.m-news-card {
	margin-bottom: 50px;
	@include media-breakpoint-down(md) {
		margin-bottom: 40px;
	}
	&__image-wrap {
		display: flex;
		margin-bottom: 30px;
		@include media-breakpoint-down(md) {
			margin-bottom: 25px;
		}
	}
	&__image {
		width: 100%;
		height: 290px;
		overflow: hidden;
		@media (min-width: 2000px) {
			max-width: 100%;
			height: 390px;
		}
		@include media-breakpoint-down(md) {
			height: 245px;
		}
		img { 
			width: 100%;
			height: 100%;
			background-size: cover;
			object-fit: cover;
		}
	}
	&__content {
		.tag {
			display: inline-flex;
			margin-bottom: 20px;
			padding: 8px 25px;
			background-color: $color-peach;
			border-radius: 200px;
			@include media-breakpoint-down(md) {
				padding: 5px 20px;
				margin-bottom: 15px;
			}
		}
		h4 {
			margin-bottom: 15px;
			a {
				color: $color-grey;
			}
			@include media-breakpoint-down(md) {
				font-size: rem(20px);
				margin-bottom: 12px;
			}
		}
	}
}

button.load-more-btn{
	margin: 0;
	padding: 0;
	background: transparent;
	color: $color-bright-blue;
	font-family: 'BasisGrotesquePro-Bold';
	font-size: rem(20px);
    line-height: rem(28px);
	&:hover {
		background: transparent;
		color: #0056b3;
	}
	@include media-breakpoint-down(md) {
		font-size: rem(16px);
    	line-height: rem(24px);
	}
}

.m-news-article {
	display: flex;
    justify-content: center;
	padding: 65px 0;
	@include media-breakpoint-down(sm) {
        padding: 30px 0 52px;
    }
	&__inner {
		margin: auto;
		max-width: 834px;
	}
	&__title {
		text-align: center;
		@include media-breakpoint-down(sm) {
			text-align: left;
		}
		.body-copy-s, .body-copy-l {
			display: block;
			margin-top: 30px;
			@include media-breakpoint-down(sm) {
				margin-top: 20px;
			}
		}
		.tag {
			display: inline-flex;
			justify-content: center;
			margin-bottom: 30px;
			padding: 8px 25px;
			background-color: $color-peach;
			border-radius: 200px;
			@include media-breakpoint-down(md) {
				padding: 5px 20px;
				margin-bottom: 15px;
			}
		}
		h1 {
			font-family: 'Canela-Regular';
			font-size: rem(56px);
			line-height: rem(58px);
			letter-spacing: -1.12px;
			@include media-breakpoint-down(sm) {
				font-size: rem(40px);
				line-height: rem(42px);
				letter-spacing: -0.8px;
			}
		}
	}
	&__image img{
		width: 100%;
		height: auto;
		margin-top: 40px;
		margin-bottom: 40px;
		@include media-breakpoint-down(sm) {
			margin-top: 20px;
			margin-bottom: 20px;
		}
	}
	&__content {
		padding-bottom: 200px;
		@include media-breakpoint-down(md) {
			padding-bottom: 92px;
		}
		.article-author{
			display: flex;
			margin-top: 30px;
			.author-image {
				margin-right: 14px;
				img{
					border-radius: 100%;
					width: 60px;
					height: 60px;
				}
			}
			.author-details {
				display: flex;
				align-items: center;
			}
		}
	}
	&__related {
		h3 {
			font-size: rem(24px);
        	line-height: rem(26px);
			margin-bottom: 45px;
			padding-bottom: 30px;
			font-family: 'BasisGrotesquePro-Medium';
			border-bottom: 1px solid rgba(8, 19, 38, 0.10);
			@include media-breakpoint-down(md) {
				font-size: rem(20px);
				line-height: rem(24px);
				margin-bottom: 20px;
				padding-bottom: 15px;
			}
		}
	}
}