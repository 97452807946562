.m-illustration {
	text-align: center;

	img, video {
		width: 100%;
		max-width: 1440px;
		margin-left: auto;
		margin-right: auto;
	}

	&__inner {
		display: block;
		@include media-breakpoint-down(sm) {
			display: none;
		}
	}

	&__inner-mobile {
        display: none;
		@include media-breakpoint-down(sm) {
            display: block;
		}
	}
}