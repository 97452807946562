.m-careers {
    margin: 80px 0 100px;
    @include media-breakpoint-down(sm) {
        margin: 50px 0 50px;
    }
    &__inner {
        &__title, &__body {
            text-align: center;
            @include media-breakpoint-down(sm) {
                text-align: left;
                padding: 0 10px;
            }
        }
        &__body {
            margin-top: 40px;
            font-size: rem(20px);
            line-height: rem(28px);
            @include media-breakpoint-down(md) {
                margin-top: 20px;
                font-size: rem(16px);
                line-height: rem(24px);
            }
        }
        &__content {
            margin-top: 80px;
            @include media-breakpoint-down(sm) {
                margin-top: 40px;
            }
            .wrapper {
                margin-bottom: 32px;
                h4 {
                    text-align: center;
                    color: var(--White, #FFF);
                    @include media-breakpoint-down(md) {
                        font-size: 16px;
                    }
                }
                .image-wrapper {
                    position: relative;
                    border-radius: 16px;
                    overflow: hidden;
                    img {
                       width: 100%;
                    }
                    .link-wrapper {
                        position: absolute;
                        top: 50%;
                        padding: 0 32px;
                        transform: translateY(-50%);
                        width: 100%;
                        justify-content: center;
                        display: flex;
                        img {
                            margin-left: 3px;
                            width: 18px;
                            height: 18px;
                        }
                    }
                    a {
                        display: block;
                        &::before {
                            content: '';
                            display: block;
                            background: rgba($color: #000000, $alpha: 0.2);
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            opacity: 0;
                            transition: all .2s ease;
                        }
                        &:hover{ 
                            &::before {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }
}