.m-subnavigation {
    display: flex;
    align-items: flex-end;
    position: sticky;
    top: 6rem;
    box-shadow: 0px 20px 20px 0 rgba(56, 65, 82, 0.04);
    background: $color-white;
    height: auto;
    min-height: 40px;
    z-index: 9;

    &.mt-30 {
        margin-top: 30px;
        @include media-breakpoint-down(md) {
            margin-top: 0;
        }
    }
    &.border {
        border-top: 1px solid #E6E7E9;
    }
    @media (max-width: 576px)  {
        // z-index: 10;
        margin-top: 2rem;
        height: auto;
    }

    .m-subnavigation-container {
        padding-left: 0;
        padding-right: 0;
        &.in-view {
            @media (max-width: 576px)  {
                border-top: 1px solid #E6E7E9;
                position: fixed;
                top: 96px;
                background: $color-white;
                box-shadow: 0px 20px 20px 0 rgba(56, 65, 82, 0.04);
            }
        }
    }
    &__inner {
        .subnavigation-wrapper {
            a {
                color: $color-black;
                font-family: 'BasisGrotesquePro-Regular';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 110%; 
            }
            &__desktop {
                display: flex;
                -webkit-mask-image: linear-gradient(90deg,transparent 0,#000 24px,#000 90%,transparent);
                overflow-x: scroll;
                overflow-y: hidden;
                scroll-behavior: smooth;
                -ms-overflow-style: none; 
                scrollbar-width: none; 
                @media (max-width: 576px) {
                    display: none;
                }
                
                &::-webkit-scrollbar { 
                    display: none;
                }
                
                .subnavigation-ul {
                    margin-left: auto;
                    margin-right: auto;
                    flex-wrap: nowrap;
                    list-style: none;
                }
                .subnavigation-item {
                    text-align: center;
                    min-width: fit-content;
                    padding: 0 30px;
                    border-bottom: 2px solid $color-grey-op-1;
                    transition: all .25s ease-in;

                    a {
                        height: 100%;
                        width: 100%;
                        display: block;
                        padding-top: 5px;
                        padding-bottom: 10px;
                        transition: all .25s ease-in;
                    }

                    &.active, &:hover {
                        border-bottom: 2px solid $color-bright-blue;
                        a {
                            opacity: 1;
                        }
                    }
                }
            }
            &__mobile {
                position: relative;
                display: none;
                overflow-x: hidden;
                overflow-y: hidden;
                scroll-behavior: smooth;
                padding: 15px 24px;
                max-height: 64px;
                will-change: max-height;
                transition: max-height .25s ease-out;
                justify-content: space-between;

                @media (max-width: 576px) {
                    display: flex !important;
                }
                .subnavigation-ul {
                    list-style: none;
                    display: grid;
                    gap: 16px;
                    margin: 0;
                    padding: 0;
                    transition: transform .25s ease-out;
                    transform: translateY(0px);
                    &.isExpanded {
                        transform: translateY(0px) !important;
                    }
                }
                .subnavigation-item {
                    white-space: nowrap;
                    scroll-snap-align: center;
                    height: 34px;
                    display: flex;
                    align-items: center;
                    padding: 0;

                    a {
                        height: 100%;
                        width: 100%;
                        display: block;
                        padding-top: 5px;
                        padding-bottom: 10px;
                        scroll-behavior: smooth
                    }

                    &.active {
                        a {
                            opacity: 1;
                        }
                    }
                }

                .dropdown-wrapper {
                    background-color: $color-bright-blue;
                    justify-content: center;
                    align-items: center;
                    margin-right: 0;
                    border-radius: 25px;
                    height: 26px;
                    width: 26px;
                    .icon-dropdown {
                        padding: 15px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}