.m-two-col-cta {
    background-color: $color-aareon-blue;
    
    h2, p {
        color: $color-white;
    }

    &__inner {
        display: block;
        padding: 100px 0;

        @include media-breakpoint-down(sm) {
            padding: 64px 0;
        }

        p {
            max-width: 510px;
        }

        .cta-btn {
            display: inline-flex;
        }

        @include media-breakpoint-down(md) {
            p {
                max-width: 100%;
            }
        }

        .right-container {
            @include media-breakpoint-down(md) {
                p {
                    max-width: 100%;
                }
            }

            @include media-breakpoint-down(sm) {
                margin-top: 58px;
            }     
        }
    }
}