.m-cookie-banner {
	position: fixed;
	bottom: 40px;
	left: 80px;
	right: 80px;
	z-index: 999;
	width: auto;
	margin: auto;
	max-width: 1280px;
	&.hide {
		display: none;
	}
	.text-link {
		color: $color-bright-blue;
	}
	@media (max-width: 1280px) {
		max-width: 100%;
		left: 40px;
		right: 40px;
	}
	@include media-breakpoint-down(md) {
		max-width: 100%;
		left: 20px;
		right: 20px;
		bottom: 20px;
	}
}
  
.m-cookie-banner__inner {     
	display: flex;
	border-radius: 16px;
	padding: 40px 52px;
	background-color: $color-white;
	box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.08);
	h4 {
		margin-bottom: 10px;
	}
	p {
		max-width: 510px;
	}
	.text-link {
		color: $color-bright-blue;
	}
	@media (max-width: 1280px) {
		padding: 40px;
	}
	@include media-breakpoint-down(lg) {
		padding: 40px;
		flex-direction: column;
		p {
			max-width: 100%;
		}
	}
	@include media-breakpoint-down(md) {
		padding: 40px;
		p {
			max-width: 100%;
		}
	}
}
  
.m-cookie-banner__content {
	width: 50%;
	.m-cookie-banner__content-wrapper {
		margin-top: 30px;
	}
	@include media-breakpoint-down(lg) {
		width: 100%;
	}
}

.m-cookie-banner__actions {
	gap: 30px;
	width: 50%;
    display: inline-flex;
    justify-content: end;
    align-items: center;
	.cta-btn {
		min-width: 170px;
		text-align: center;
	}
	.cta-btn, .link-large {
		margin: 0;
	}
	@include media-breakpoint-down(lg) {
		width: 100%;
		margin-top: 40px;
		justify-content: flex-start;
	}
	@include media-breakpoint-down(md) {
		margin-top: 20px;
		.link-large {
			margin-top: 20px;
			width: fit-content;
		}
	}
	@include media-breakpoint-down(sm) {
		display: block;
		.cta-btn, .link-large button {
			display: block;
			text-align: center;
			min-width: 100%;
		}
		.cta-btn {
			margin-top: 20px
		}
	}
}

.m-cookie-settings {
	position: fixed;
	bottom: 40px;
	left: 80px;
	right: 80px;
	z-index: 999;
	width: auto;
	margin: auto;
	max-width: 1280px;
	display: none;
	h3 {
		font-family: 'Canela-Regular';
		font-size: rem(40px);
        line-height: rem(42px);
		letter-spacing: -0.8px;
	}
	&.active {
		display: block;
	}
	&.hide {
		display: none;
	}
	.m-cookie-banner__inner {     
		padding: 70px 110px;
		@media (max-width: 1280px) {
			padding: 40px;
		}
		@include media-breakpoint-down(lg) {
			padding: 40px;
			flex-direction: column;
			p {
				max-width: 100%;
			}
		}
		@include media-breakpoint-down(md) {
			padding: 40px;
			p {
				max-width: 100%;
			}
		}
	}
	.m-cookie-banner__actions {
		gap: 0;
		display: flex;
		justify-content: flex-start;
		align-items: flex-end;
		flex-direction: column;
		.buttons {
			gap: 20px;
			display: flex;
		}
		.settings {
			max-width: 400px;
			margin-top: 60px;
			padding-top: 30px;
			padding-bottom: 30px;
			border-top: 1px solid rgba(8, 19, 38, 0.10);
			border-bottom: 1px solid rgba(8, 19, 38, 0.10);
			.item {
				width: 100%;
				display: inline-flex;
				justify-content: space-between;
				padding: 20px 0;
				div {
					display: inline-flex;
					align-items: center;
				}
				img {
					width: 32px;
					height: 32px;
					margin-right: 8px;
				}
			}
			@include media-breakpoint-down(lg) {
				width: 100%;
				max-width: 100%;
			}
		}
		@include media-breakpoint-down(lg) {
			width: 100%;
			margin-top: 40px;
			justify-content: flex-start;
			div {
				width: 100%;
			}
		}
		@include media-breakpoint-down(sm) {
			display: block;
			.cta-btn, .link-large button {
				display: block;
				text-align: center;
				min-width: 100%;
			}
			.cta-btn {
				margin-top: 20px
			}
			.buttons {
				display: block;
			}
		}
	}
	@include media-breakpoint-down(lg) {
		max-width: 100%;
		left: 40px;
		right: 40px;
		height: calc(100vh - 80px);
		overflow: scroll;
	}
	@include media-breakpoint-down(md) {
		max-width: 100%;
		left: 20px;
		right: 20px;
		bottom: 20px;
		height: calc(100vh - 40px);
	}
}

.switch {
    display: inline-block;
    position: relative;
    width: 50px;
    height: 28px;
    border-radius: 20px;
    background: #CCCCCC;
    transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    vertical-align: middle;
    cursor: pointer;
}
.switch::before {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    width: 22px;
    height: 22px;
    background: #fafafa;
    border-radius: 50%;
    transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}
input:checked + .switch {
    background: $color-bright-blue;
}
input:checked + .switch::before {
    left: 25px;
    background: #fff;
}
input:checked:disabled + .switch {
    background: #666666;
	cursor: auto;
}