.link {
    margin-top: $link-margin-top;
    a {
        display: flex;
        align-items: center;
        &:hover{
            img {
                filter: brightness(0.7);
            }
        }
    }
    img {
        margin-left: 3px;
        width: 15px;
        height: 15px;
        transition: all .2s ease;
    }
    @include media-breakpoint-down(md) {
        margin-top: 20px;
    }
}

.link-large {
    margin-top: $link-margin-top;
    a {
        display: flex;
        align-items: center;
        font-size: rem(20px);
	    line-height: rem(28px);
        font-family: 'BasisGrotesquePro-Bold';
        &:hover{
            img {
                filter: brightness(0.7);
            }
        }
    }
    button {
        display: flex;
        align-items: center;
        font-size: rem(20px);
	    line-height: rem(28px);
        font-family: 'BasisGrotesquePro-Bold';
        padding: 0;
        margin: 0;
        background: transparent;
        color: $color-bright-blue;
        &:hover {
            color: #0056b3;
            background-color: transparent;
        }
    }
    img {
        margin-left: 5px;
        width: 20px;
        height: 20px;
        transition: all .2s ease;
    }
    @include media-breakpoint-down(md) {
        a, button {
            font-size: rem(16px);
	        line-height: rem(24px);
        }
    }
}

.inline-link {
    display: inline-flex;
    align-items: center;
    transition: all .2s ease;
    img {
        margin-left: 3px;
        width: 15px;
        height: 15px;
        transition: all .2s ease;
    }
    &:hover{
        img {
            filter: brightness(0.7);
        }
    }
}

.text-link { 
    font-size: rem(20px);
    line-height: rem(28px);
    color: $color-grey;
    font-weight: $font-regular;
    text-decoration: underline;
    font-family: 'BasisGrotesquePro-Regular';
    &:hover{
        text-decoration: underline;
        color: $color-aareon-blue;
    }
}

.body-copy-l .text-link { 
    font-size: rem(20px);
    line-height: rem(28px);
    @include media-breakpoint-down(md) {
        font-size: rem(16px);
	    line-height: rem(24px);
    }
}

.body-copy-m .text-link { 
    font-size: rem(16px);
	line-height: rem(24px);
}