.cta-btn {
    background: $color-bright-blue;
    color: $color-white;
    border-radius: 200px;
    padding: 16px 28px;
    border: 0;
    margin-top: $btn-margin-top;
    font-size: rem(20px);
    line-height: rem(22px);
    font-family: 'BasisGrotesquePro-Medium';
    &:hover {
        color: $color-white;
        background-color: $color-btn-hover;
    }
    @include media-breakpoint-down(md) {
        font-size: rem(16px);
        line-height: rem(19px);
        padding: 14px 24px;
    }
}
