.m-hero {
    &.bordered {
        .m-hero__inner__plain {
            width: 100%;
            margin: auto;
            border-bottom: 1px solid rgba(8, 19, 38, 0.1);
            .primary-wrapper {
                padding: 0;
            }
        }
    }
    &.notfound {
        .m-hero__inner__plain {
            .primary-content-wrapper {
                .text {
                    font-family: 'BasisGrotesquePro-Regular';
                    font-size: rem(20px);
		            line-height: rem(28px);
                }
                .image {
                    max-width: 400px;
                    margin: auto;
                }
            }
        }
    }
    &__inner__plain {
        padding: 60px 0;
        @include media-breakpoint-up(lg) {
            flex-direction: row-reverse;
        }
        @include media-breakpoint-down(sm) {
            padding: 40px 0;
        }
        .primary-wrapper {
            @include media-breakpoint-down(sm) {
                justify-content: unset;
            }
        }
        
        .primary-content-wrapper {
            &.align-center {
                text-align: center;
                .body, .text {
                    padding: 0 100px;
                    @include media-breakpoint-down(md) {
                        padding: 0 0px;
                    }
                }
                @include media-breakpoint-down(sm) {
                    text-align: left;
                }
            }
            > * {
                margin-top: 30px;
                margin-bottom: 30px;
                @include media-breakpoint-down(sm) {
                    margin-top: 30px;
                    margin-bottom: 30px;

                    &:first-child {
                        margin-top: 0px;
                    }
                }
            }
            .title {
                font-family: 'Canela-Regular';
                line-height: 105%;
                @include media-breakpoint-down(md) {
                    font-size: 48px;
                }
            }
            .body, .pre-title {
                @include media-breakpoint-down(sm) {
                    font-size: 20px;
                }
            }
            .text {
                font-size: 24px;
                max-width: 990px;
                margin: auto;
                font-family: 'BasisGrotesquePro-Regular';
                @include media-breakpoint-down(sm) {
                    font-size: 16px;
                }
            }
            .link {
                @include media-breakpoint-down(sm) {
                    justify-content: left;
                    .cta-btn {
                        margin-top: 0;
                    }
                }
                
            }
            .image {
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}