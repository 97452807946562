@import "aos";
@import "bootstrap";
@import "reset";
@import "swiper";

[data-aos="fade-right"] {
  transform: translateX(-25px);
  opacity: 0;
  transition-property: transform, opacity;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  &.aos-animate {
    transform: translateX(0);
    opacity: 1;
  }
}

[data-aos="fade-in"] {
  transform: translateY(25px);
  opacity: 0;
  transition-property: transform, opacity;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  &.aos-animate {
    transform: translateY(0);
    opacity: 1;
  }
}

[data-aos="fade-in-place"] {
  opacity: 0;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  &.aos-animate {
    opacity: 1;
  }
}

[data-aos="move-up-left"] {
  transform: translate3d(100px,100px,0);
  &.aos-animate {
    transform: translate3d(0,0,0);
  }
  @include media-breakpoint-down(md) {
    transform: translate3d(20px,20px,0);
  }
}

[data-aos="move-down-right"] {
  opacity: 0;
  transform: scale(0.6);
  &.aos-animate {
    opacity: 1;
    transform: scale(1);
  }
  @include media-breakpoint-down(md) {
    transform: scale(0.8);
  }
}
