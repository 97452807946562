.m-stats {
    &__inner {
        display: block;
        padding: 80px 0 70px 0;
        
        @include media-breakpoint-down(sm) {
            display: none;
        }
	}
    
    &__inner-mobile {
        display: none;
        padding: 30px 0 60px 0;
        width: 100%;
        
        @include media-breakpoint-down(sm) {
            display: flex;
        }
        
        .stats {
            margin-bottom: 40px;
        }
	}

    h2 {
        font-family: 'BasisGrotesquePro-Medium';
        letter-spacing: -3.36px;

        @include media-breakpoint-down(lg) {
            font-size: 38px;
            line-height: 40px;
            letter-spacing: -2.4px;
        }

        @include media-breakpoint-down(sm) {
            margin-bottom: 6px;
        }
    }
    .text-white {
        p {
            color: $color-white;
        }
    }

}

// Conditional styling
.text-white {
    h2, p {
        color: $color-white;
    }
}

.background-white {
    background-color: $color-white;
}

.background-blue {
    background-color: $color-aareon-blue;
}

.border {
    border-top: 1px solid $color-line;
    border-radius: 2px;
}

.m-two-col-img-headline-intro + .m-stats.background-blue {
    margin-top: 60px;
}