.m-usp {
    border-radius: 16px;
    flex-shrink: 0;

    &.usp-col-4, &.usp-col-3 {
        width: 100%;
        height: auto;
        margin-bottom: 20px;
        
        @include media-breakpoint-down(lg) {
            width: 100%;
            height: auto;
            min-height: auto;
        }

        @include media-breakpoint-down(sm) {
            width: 100%;
            height: auto;
        }

        .wrapper {
            height: 100%;
            margin: 0;
            padding: 0px;

            .image-wrapper {
                padding: 0;
                img {
                    width: 80px;
                    height: 80px;
                    left: 40px;
                    top: 35px;

                    @include media-breakpoint-down(lg) {
                        width: 40px;
                        height: 40px;
                        left: 20px;
                    }
                }
            }

            .text-wrapper {
                display: flex;
                flex-direction: column;

                @include media-breakpoint-down(lg) {
                    align-self: start;
                    padding-top: 35px;
                    padding-bottom: 35px;
                }

                @include media-breakpoint-down(sm) {
                    align-self: start;
                    padding-top: 35px;
                    padding-bottom: 35px;
                }

                h4 {
                    margin-bottom: 0;
                }

                p {
                    font-size: 16px;
                    line-height: 22px;
                }

                h4 + p {
                    margin-top: 10px;
                }
            }
        }
    }

    &.usp-col-4 {
        min-height: 258px;
        margin-bottom: 40px;

        @media (min-width: 2000px) {
            min-height: 300px;
        }

        @include media-breakpoint-down(lg) {
            min-height: auto;
        }

        @include media-breakpoint-down(md) {
            margin-bottom: 20px;
        }

        @include media-breakpoint-down(sm) {
        }

        // img {
        //     position: absolute
        // }

        .wrapper {
            .image-wrapper {
                padding: 40px;
                @media (max-width: 1280px) {
                    padding: 30px 20px 30px 20px;
                }
                @include media-breakpoint-down(md) {
                    padding: 40px 0 40px 30px;
                    img {
                        left: auto;
                    }
                }
            }

            .text-wrapper {
                align-self: end;
                padding: 0 40px 40px 40px;
                @media (min-width: 1200px) and (max-width: 1280px) {
                    padding: 20px 20px 40px 20px;
                }
                @include media-breakpoint-down(lg) {
                    padding: 40px 35px 40px 15px;
                    align-self: start;
                }
                @include media-breakpoint-down(md) {
                }
            }
        }
    }

    &.usp-col-3 {
        width: 100%;
        height: auto;
        min-height: 197px;
        margin-bottom: 40px;

        @media (min-width: 2000px) {
            min-height: 297px;
        }

        @include media-breakpoint-down(md) {
            width: 100%;
            margin-bottom: 20px;
        }

        @include media-breakpoint-down(sm) {
            width: 100%;
            height: 160px;
        }

        .wrapper {
            padding: 32px 20px 12px 20px;

            .image-wrapper {
                justify-content: center;
                align-items: center;
                display: flex;

                img {
                    width: 64px;
                    height: 64px;

                    @include media-breakpoint-down(md) {
                        width: 40px;
                        height: 40px;
                    }
                }
            }

            .text-wrapper {
                display: flex;
                text-align: center;
                padding-top: 12px;
                padding-bottom: 20px;
                padding-left: 0;
                padding-right: 0;

                h4 {
                    margin-bottom: 0;
                }

                @include media-breakpoint-down(md) {
                    padding-right: 0;
                    padding-left: 0;
                }
            }
        }
    }
}
