.breadcrumb {
  display: flex;
  margin-bottom: 70px;
  .breadcrumb-item{
    padding-right: 4px;
    font-size: rem(16px);
		line-height: rem(22px);
		color: $color-grey;
    font-family: 'BasisGrotesquePro-Regular';
    a{
      font-family: 'BasisGrotesquePro-Bold';
      font-size: rem(16px);
		  line-height: rem(22px);
      color: $color-bright-blue;
      padding-right: 2px;
      svg {
        margin-top: -4px;
      }
      &:hover{
        color: #0552E8;
        svg path{
          stroke: #0552E8;
        }
      }
    }

    &:after{
      content: '/';
    }

    &:last-child{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      min-width: 0;
      max-width: 75%;

      &:after{
        content: '';
      }
    }
  }

  @include media-breakpoint-down(md) {
    margin-bottom: 30px;
		.breadcrumb-item{
      min-width: fit-content;
      font-size: rem(15px);
      line-height: rem(20px);
      a{
        font-size: rem(15px);
        line-height: rem(20px);
      }
      &:last-child{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        min-width: 0;
      }
    }
	}

  @include media-breakpoint-down(sm) {
    padding: 0 5px;
  }
}
