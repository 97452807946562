.m-card {
    border-radius: 16px;
    flex-shrink: 0;
    height: 100%;
    &.slider-card {
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }
    .wrapper {
        &__inner {
            &__downloads-card {
                padding: 50px 40px;
                margin-left: auto;
                margin-right: auto;
                @include media-breakpoint-down(sm) {
                    padding: 40px 20px;
                    .p-0 {
                        padding: 0!important;
                    }
                    .pl-0 {
                        padding-left: 0!important;
                    }
                }
                .title-wrapper {
                    margin-bottom: 20px;
                    @include media-breakpoint-down(md) {
                        margin-bottom: 40px;
                    }
                }
                .body-wrapper {
                    h4 {
                        margin-bottom: 25px;
                    }
                }
                .body-wrapper + .body-wrapper {
                    &::before {
                        content: '';
                        display: flex;
                        height: 2px;
                        width: 100%;
                        border-top: solid 2px rgba(8, 19, 38, 0.1);
                        margin: 60px 0;
                        @include media-breakpoint-up(lg) {
                            height: 100%;
                            width: 2px;
                            border-top: none;
                            border-left: solid 2px rgba(8, 19, 38, 0.1);
                            margin: 0;
                            margin-right: 60px;
                            float: left;
                        }
                    } 
                }
            }

            &__news-card {
                padding: 30px 40px;
                margin-left: auto;
                margin-right: auto;
                @include media-breakpoint-down(sm) {
                    padding: 40px 20px;
                    .p-0 {
                        padding: 0!important;
                    }
                }
                .title-wrapper {
                    margin-bottom: 22px;
                }
                .body-wrapper {
                    margin-bottom: 12px;
                    margin-right: auto;

                    .body-title-news {
                        margin-bottom: 12px;
                    }
                    .body-text-news {
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                }
                .image-wrapper {
                    @include media-breakpoint-up(xl) {
                        padding: 0;
                        display: flex;
                        justify-content: end;
                        align-items: end;
                    }

                    img {
                        width: 100%;
                        max-width: 200px;
                        height: 135px;
                        object-fit: cover;
                    }
                }
                .link  {
                    font-size: rem(20px);
                    line-height: rem(28px);
                    @include media-breakpoint-down(md) {
                        font-size: rem(16px);
                        line-height: rem(24px);
                    }
                }
            }

            &__col-1, &__col-2 {
                margin-left: auto;
                margin-right: auto;
                .title-wrapper {
                    margin-bottom: 22px;
                }
            }
            &__col-1 {
                padding: 40px;
                @include media-breakpoint-down(sm) {
                    padding: 40px 20px;
                }
                .body-wrapper {
                    p {
                        margin-bottom: 24px;
                    }
                    .link  {
                        font-size: rem(20px);
                        line-height: rem(28px);
                        @include media-breakpoint-down(md) {
                            font-size: rem(16px);
                            line-height: rem(24px);
                        }
                    }
                }
                &__slider {
                    height: 100%;
                    position: relative;
                    padding: 0;
                    .title-wrapper, .body-wrapper  {
                        max-width: 100%;
                        padding: 40px 70px 0 40px;
                        @include media-breakpoint-down(sm) {
                            padding: 22px 22px 0 22px;
                            margin-bottom: 12px;
                            h3{
                                font-size: rem(28px);
                                line-height: rem(30px);
                            }
                        }
                    }
                    .body-wrapper {
                        padding-top: 0;
                    }
                    .card-slider-container {
                        display: flex;
                        align-items: flex-end;
                        flex-wrap: nowrap;
                        overflow: hidden;
                        width: 100%;
                        justify-content: flex-start;
                        &.right-slider {
                            justify-content: flex-end;
                        }
                        .card-slider-wrapper {
                            padding: 40px 0 60px;
                            width: max-content;
                            @include media-breakpoint-down(sm) {
                                padding: 20px 0 40px;
                            }
                            .card-slider-content {
                                justify-content: flex-start;
                                padding: 0px 0;
                                flex-wrap: nowrap;
                                display: flex;
                                gap: 20px;
                                img {
                                    height: 245px;
                                    width: 245px;
                                    border-radius: 50%;
                                    object-fit: cover;
                                    @include media-breakpoint-down(lg) {
                                        height: 200px;
                                        width: 200px;
                                    }
                                    @media (max-width: 500px) {
                                        height: 170px;
                                        width: 170px;

                                    }
                                }
                            }
                        }
                    }
                }
            }
            &__col-2 {
                padding: 40px;
                @include media-breakpoint-down(sm) {
                    padding: 40px 20px;
                    .p-0 {
                        padding: 0!important;
                    }
                    .pl-0 {
                        padding-left: 0!important;
                    }
                }
                .body-wrapper {
                    margin-bottom: 32px;
                    .body-title {
                        font-size: 20px;
                        font-weight: 700;
                    }
                    p {
                        font-size: 16px;
                        padding-bottom: 0;
                    }
                    p + p {
                        margin-top: 5px;
                    }
                }
            }
        }
    }
}