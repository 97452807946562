.s-about {
    position: relative;
    padding: 0 !important;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
    height: 850px;
    overflow: hidden;
    display: block;
    width: 100%;
    @include media-breakpoint-down(md) {
        height: 650px;
    }
    @include media-breakpoint-down(sm) {
        height: auto;
    }

    .primary-wrapper-about {
        align-items: center;
        max-width: 1000px;
        text-align: center;
        .primary-content-wrapper-about {
            > * {
                margin-top: 0;
                margin-bottom: 30px;
                @include media-breakpoint-down(md) {
                    margin-top: 30px;
                    margin-bottom: 30px;
                }
            }
            .title {
                font-family: 'Canela-Regular';
                line-height: 105%;
                font-size: 72px;
                @include media-breakpoint-down(md) {
                    font-size: 40px;
                }
                @include media-breakpoint-down(sm) {
                    padding-right: 20px;
                    padding-left: 20px;
                }
            }
            .body, .pre-title {
                font-size: 24px;
                @include media-breakpoint-down(md) {
                    font-size: 16px;
                }
            }
            .text {
                font-size: 16px;
                @include media-breakpoint-down(sm) {
                    font-size: 15px;
                }
            }
            .link {
                @include media-breakpoint-down(sm) {
                    justify-content: left;
                }
            }
            .body-copy-l {
                color: $color-grey;
                font-family: 'BasisGrotesquePro-Medium';
                font-size: 24px;
                line-height: 26px;
                max-width: 870px;
                @include media-breakpoint-down(md) {
                    font-size: rem(16px);
                    line-height: rem(24px);
                }
            }
        }
    }

    &__images {
        position: absolute;
        padding: 20px 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        @include media-breakpoint-down(sm) {
            display: none;
        }
        .images-container { 
            position: relative;
            justify-content: center;
            display: flex;
            height: 100%;
            &__inner {
                position: relative;
                height: 100%;
                width: 100%;
                max-width: 1500px;
                min-width: 1200px;
                @include media-breakpoint-down(lg) {
                    min-width: 950px;
                }
                @include media-breakpoint-down(md) {
                    min-width: 850px;
                }
                .image-wrapper {
                    max-width: 310px;
                    max-height: 200px;
                    position: absolute;
                    @include media-breakpoint-down(lg) {
                        max-width: 225px;
                        max-height: 150px;
                    }  
                    @include media-breakpoint-down(md) {
                        max-width: 225px;
                        max-height: 150px;
                    } 
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        background-size: cover;
                    }
                    &__1 {
                        left: 25%;
                        transition-property: transform, opacity;
                        transition-delay: 375ms;
                        @include media-breakpoint-down(sm) {
                            left: 5%;
                        }
                        img {
                            max-height: 210px;
                            @include media-breakpoint-down(lg) {
                                max-height: 150px;
                            }
                            @media (max-width: 360px) {
                                max-width: 142px;
                                max-height: 118px;
                            }
                        }
                    }
                    &__2 {
                        right: 30%;
                        top: 5%;
                        transition-property: transform, opacity;
                        transition-delay: 500ms;
                        @include media-breakpoint-down(sm) {
                            right: 5%;
                        }
                        img {
                            max-width: 204px;
                            max-height: 130px;
                            @include media-breakpoint-down(lg) {
                                max-height: 100px;
                            }
                        }
                    }
                    &__3 {
                        right: 0%;
                        top: 18%;
                        transform: translateX(30%);
                        transition-property: transform, opacity;
                        transition-delay: 725ms;
                        @include media-breakpoint-down(sm) {
                            display: none;
                        }
                        img {
                            max-width: 300px;
                            max-height: 243px;
                            @include media-breakpoint-down(lg) {
                                max-height: 150px;
                            }
                            @include media-breakpoint-down(md) {
                                max-height: 120px;
                            }
                        }
                    }
                    &__4 {
                        right: 3%;
                        bottom: 10%;
                        transition-property: transform, opacity;
                        transition-delay: 725ms;
                        @include media-breakpoint-down(sm) {
                            right: 0;
                        }
                        img {
                            max-width: 266px;
                            max-height: 225px;
                            @include media-breakpoint-down(lg) {
                                max-height: 150px;
                            }
                        }
                    }
                    &__5 {
                        left: 48%;
                        bottom: 2%;
                        transition-property: transform, opacity;
                        transition-delay: 500ms;
                        @include media-breakpoint-down(md) {
                            transform: translateX(-30%);
                        }
                        img {
                            max-width: 328px;
                            max-height: 205px;
                            @include media-breakpoint-down(lg) {
                                max-height: 120px;
                            }
                        }
                    }
                    &__6 {
                        left: 16%;
                        bottom: 4%;
                        transition-property: transform, opacity;
                        transition-delay: 375ms;
                        @include media-breakpoint-down(md) {
                            left: 15%;
                        }
                        img {
                            max-width: 254px;
                            max-height: 193px;
                            @include media-breakpoint-down(lg) {
                                max-width: 180px;
                                max-height: 120px;
                            }
                        }
                    }
                    &__7 {
                        left: 2%;
                        top: 30%;
                        transform: translateX(-35%);
                        transition-property: transform, opacity;
                        transition-delay: 250ms;
                        img {
                            max-width: 210px;
                            max-height: 290px;
                            @include media-breakpoint-down(lg) {
                                max-height: 150px;
                            }
                            @include media-breakpoint-down(md) {
                                max-height: 120px;
                            }
                        }
                    }
                }
            }
        }
    }
    .images-top-mobile-container {
        transition-property: transform, opacity;
        transition-delay: 250ms;
        max-width: 500px;
        @include media-breakpoint-down(sm) {
            display: block !important;
        }
        .images-top-mobile-row {
            justify-content: center;
            height: 100%;
            width: 100%;
            margin-right: auto;
            margin-left: auto;

            .image-top-container-mobile {
                height: 100%;
                position: static;
                padding-right: 0;
                padding-left: 0;
                justify-content: center;
                display: flex;
                .image-wrapper-mobile {
                    height: 100%;
                    width: fit-content;
                    justify-content: center;
                    display: flex;
                    img {
                        width: 100%;
                        height: 100%;
                        background-size: cover;
                        object-fit: cover;
                    }
                    &__1 {
                        width: 162px;
                        height: 138px;
                        transition-property: transform, opacity;
                        transition-delay: 250ms;
                    }
                    &__2 {
                        display: flex;
                        align-items: center;
                        transition-property: transform, opacity;
                        transition-delay: 500ms;
                        img {
                            width: 70%;
                            height: auto;
                        }
                    }
                }
            }
        }
    }
    .images-bottom-mobile-container {
        max-width: 500px;
        padding: 0;
        @include media-breakpoint-down(sm) {
            display: block !important;
        }
        .images-bottom-mobile-row {
            justify-content: center;
            height: 100%;
            width: 100%;
            margin-right: auto;
            margin-left: auto;
            flex-direction: row-reverse;
            :nth-child(3){
                justify-content: flex-start;
                padding-right: 10px;
            }
            :nth-child(2){
                justify-content: center;
            }
            :nth-child(1){
                justify-content: flex-end;
            }
            .image-bottom-container-mobile {
                height: 100%;
                position: static;
                padding-right: 0;
                padding-left: 0;
                display: flex;
                .image-wrapper-mobile {
                    height: 100%;
                    width: fit-content;
                    justify-content: center;
                    display: flex;
                    img {
                        width: 100%;
                        height: 100%;
                        background-size: cover;
                        object-fit: cover;
                    }
                    &__1, &__3 {
                        width: 113px;
                        height: 86px;
                    }
                    &__1 {
                        justify-content: flex-start;
                        margin-left: 5px;
                        transition-property: transform, opacity;
                        transition-delay: 500ms;
                    }
                    &__2 {
                        margin-top: 50px;
                        width: 128px;
                        height: 81px;
                        transition-property: transform, opacity;
                        transition-delay: 375ms;
                    }
                    &__3 {
                        justify-content: flex-end;
                        margin-right: 5px;
                        transition-property: transform, opacity;
                        transition-delay: 250ms;
                    }
                }
            }
        }
    }
}