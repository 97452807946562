.download-link {
    margin-top: $link-margin-top;
    a {
        display: flex;
        align-items: center;
    }
    img {
        margin-right: 20px;
        width: 48px;
        height: 48px;
    }
}