$grid-gutter-width: 50px;
$grid-gutter-width--tablet: 30px;
$grid-gutter-width--mobile: 40px;
$grid-columns: 12;

$grid-breakpoints: (
  xs: 0,
  sm: 540px,
  md: 720px,
  lg: 992px,
  xl: 1200px,
);

$container-max-widths: (
  sm: 576px,
  md: 768px,
  lg: 960px,
  xl: 1650px,
);

// Required
@import "bootstrap/functions";
@import "bootstrap/variables";
@import "bootstrap/mixins";

// Optional
@import "bootstrap/reboot";
@import "bootstrap/grid";

// Utilities
@import "bootstrap/utilities/clearfix";
@import "bootstrap/utilities/display";
@import "bootstrap/utilities/embed";

$container-max-width-xl: map-get($container-max-widths, "xl");
$container-max-width-lg: map-get($container-max-widths, "lg");
$container-max-width-md: map-get($container-max-widths, "md");
$container-max-width-sm: map-get($container-max-widths, "sm");


// gutter change hack
@media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md)){
  .row {
    margin-right: calc((#{$grid-gutter-width--tablet} / 2) * -1);
    margin-left: calc((#{$grid-gutter-width--tablet} / 2) * -1);
  }
  .row > .col,
  .row > [class*="col-"] {
    padding-right: calc(#{$grid-gutter-width--tablet} / 2);
    padding-left: calc(#{$grid-gutter-width--tablet} / 2);
  }
  .container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)){
  .row {
    margin-right: calc((#{$grid-gutter-width--mobile} / 2) * -1);
    margin-left: calc((#{$grid-gutter-width--mobile} / 2) * -1);
  }
  .row > .col,
  .row > [class*="col-"] {
    padding-right: calc(#{$grid-gutter-width--mobile} / 2);
    padding-left: calc(#{$grid-gutter-width--mobile} / 2);
  }
  .container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    padding-right: 20px;
    padding-left: 20px;
  }
}