.m-two-col-headline-intro {
    padding: 60px 0;

    h2 {
        font-family: 'Canela-Regular';
    }
    
    &__inner {
        padding: 50px 0;
        display: block;

        .col + .col {
            p {
                max-width: 510px;
                @media (min-width: 2000px) {
                    max-width: 840px;
                }
            }
        }

        .link {
            a {
                display: flex;
                align-items: center;
                font-size: rem(20px);
                line-height: rem(28px);
                font-family: 'BasisGrotesquePro-Bold';
            }
            img {
                margin-left: 5px;
                width: 20px;
                height: 20px;
            }
            @include media-breakpoint-down(md) {
                a {
                    font-size: rem(16px);
                    line-height: rem(24px);
                }
            }
        }
        
        @include media-breakpoint-down(sm) {
            display: none;
        }
	}

    &__inner-mobile {
        display: none;
        
        @include media-breakpoint-down(sm) {
            display: block;
            padding: 0;

            h2{
                margin-bottom: 20px;
            }
        }
	}

    &__inner-blue {
        padding: 50px 40px;
        border-radius: 16px;
        background-image: url("bg-pattern.svg");
        background-color: $color-aareon-blue;
        background-repeat: no-repeat;
        background-size: cover;

        h2 {
            color: $color-white;
            padding-bottom: 120px;
            @include media-breakpoint-down(md) {
                font-size: rem(40px);
		        line-height: rem(42px);
            }
        }

        p {
            color: $color-white;
            max-width: 80%;
        }

        ul {
            margin: 0;
            max-width: 86%;
            list-style-type: disc;
            padding-left: 20px;

            li{
                color: $color-white;
                font-size: rem(20px);
		        line-height: rem(28px);
            }
        }

        .cta-btn {
            display: inline-flex;
        }

        @include media-breakpoint-down(md) {
            padding: 45px 25px;
            background-image: url("bg-pattern-mobile.svg");

            h2 {
                padding-bottom: 80px;
            }

            p {
                max-width: 100%;
            }

            ul {
                max-width: 100%;
                padding-left: 20px;
    
                li{
                    font-size: rem(16px);
                    line-height: rem(22px);
                }
            }
        }
	}
}

.background-black {
    background-color: $color-black;
    .container {
        padding-right: 40px;
        padding-left: 40px;
        @include media-breakpoint-down(sm) {
            padding-right: 20px;
            padding-left: 20px;
        }
    }
}

.m-center-headline-intro__inner-mobile {
    .cta-btn {
        display: inline-flex;
        margin-top: 34px;
        @include media-breakpoint-down(sm) {
            margin-top: 20px;
        }
    }
}
