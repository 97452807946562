@mixin FadeIn {
	visibility: visible;
	opacity: 1;
	transition: opacity 0.1 ease;
}
  
@mixin FadeOut {
	visibility: hidden;
	opacity: 0;
}

.m-header {
	z-index: 10;
	background-color: $color-white!important;
	transition: all 0.6s ease;
	position: fixed;
	width: 100%;
	top: 0;

	&__inner {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: rem(96px);
	}

	&__logo {
		height: 100%;
		display: flex;
		align-items: center;
		z-index: 2;

		h2 {
			margin-bottom: 0;
		}
	}

	&__navigation {
		height: 100%;

		@include media-breakpoint-down(lg) {
			display: none;
		}

		p, span {
			color: $color-black;
		}
		
		& > ul {
			display: flex;
			height: 100%;
		}
		
		.lang-ul {
			width: 0;
		}

		li {
			display: flex;
			align-items: center;
			overflow: hidden;

			&:not(:first-of-type) {
				margin-left: rem(32px);
			}

			a {
				font-size: rem(16px);
				line-height: rem(18px);
				color: $color-black;
				font-family: 'BasisGrotesquePro-Regular';

				&:hover {
					text-decoration: none;
				}
			}
		}

		&--list {
			display: flex;
			justify-content: center;
			align-items: center;

			.item-with-submenu {
				cursor: default;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}

		.item-without-submenu, .item-with-submenu {
			transition: opacity 0.1s ease;
			&:hover {
				opacity: 0.7;
			}
		}
	

		.subpages-container, .language-menu {
			@include FadeOut();
			border-radius: 16px;
			background-color: $color-white;
			width: max-content; 
			height: fit-content;
			position: absolute;
			top: rem(106px);
			padding: 40px 0;
			z-index: 1;

			.container {
				padding-right: 40px;
				padding-left: 40px;
			}
			
			.category {
				font-size: 16px;
				cursor: default;
				padding-bottom: 5px;
			}

			.menu-item {
				padding-top: 15px;
				cursor: pointer;
				display: block;
				transition: opacity 0.3s ease;

				.row {
					align-items: flex-start;
				}

				&.no-padding {
					padding-top: 0;
				}

				&.not-active {
					opacity: 0.5;
				}
			}
			
			.title {
				font-size: 20px;
				line-height: 22px;
				max-width: 210px;
			}

			.description {
				font-size: 14px;
				line-height: 18px;
				color: $color-grey;
				padding-left: 6px;
				padding-top: 2px;
				max-width: 274px;
				margin-top: 2px;
				@media (max-width: 1280px) {
					max-width: 210px;
				}
			}

			.icon {
				margin-top: 4px;
				margin-right: 13px;
				width: 16px;
				height: 16px;
			}

			.language-wrapper {
				&:not(:first-child) {
					margin-left: 70px;
				}

				.language-item {
					padding-top: 22px;
					display: flex;
					align-items: center;
					
					.arrow {
						margin-left: 4px;
					}
				}

				.language-page, .language {
					position: relative;
					background-color: $color-white;
					transition: opacity 0.3s ease;

					&.not-active {
						opacity: 0.5;
					}
				}
			}
			
			&.hovered {
				height: initial;
				@include FadeIn();
			}
		}

		.language-menu {
            right: 0;
		}
	}

	&__buttons {
		z-index: 2;
		display: flex;
		flex-direction: row;

		button, a {
			display: block;
			margin-top: 0;
			padding: 12px 24px;
			border-radius: 200px;
			color: $color-white;
			background: $color-bright-blue;
			font-size: 16px;
			line-height: 20px;
			font-family: 'BasisGrotesquePro-Regular';
			&:hover{
				background-color: #0552E8;
			}

			@include media-breakpoint-down(lg) {
				display: none;
			}
		}

		.hamburger {
			display: none;
			cursor: pointer;

			@include media-breakpoint-down(lg) {
				display: block;
			}

			&--inactive {
				display: none;
			}
		}

		.cross {
			display: none;
			cursor: pointer;

			&--active {
				display: block;
			}
		}

		.globe {
			margin-right: 20px;
			position: relative;

			@include media-breakpoint-down(lg) {
				cursor: pointer;
			}

			&--inactive {
				display: none;
			}
		}
	}

	&__navigation-mobile-language, &__navigation-mobile {
		display: none;
		position: fixed;
		width: 100%;
		z-index: 1;
		overflow-y: scroll;
		inset: 0;
		background-color: $color-white;	
		top: rem(96px);
		
		&--active {
			display: block;
		}
	}	

	&__navigation-mobile {
		z-index: 2;
		padding-bottom: 400px;

		.navigation-items {
			list-style: none;
			margin-left: 0;

			a, p {
				font-size: 24px;
				color: $color-black;
				font-family: 'BasisGrotesquePro-Regular';
			}
			
			.m-header__navigation-mobile--list {
				&:not(:first-child) {
					border-top: 1.5px solid $color-line;
				}
			}

			.item-title {
				display: flex;
				flex-direction: row;
				width: 100%;
				justify-content: space-between;
				margin: 28px 0;

				.chevron {
					transition: all 0.5s ease-in-out;
					
					&--rotate {
						transform: rotate(180deg);
					}
				}
			}

			.submenu-wrapper {
				overflow: hidden;

				.submenu {
					transition: all 0.6s ease-in-out;
          			margin-top: -180%;
					padding-bottom: 25px;
		
					&--active {
						margin-top: 0;
					}
	
					.category {
						font-size: 14px;
						padding: 10px 0;
					}
	
					.submenu-item {
						padding: 8px 0;
						width: 100%;
						display: block;

						a {
							font-size: 20px;
							line-height: 22px;
						}
						
						.title-icon {
							display: flex;
							flex-direction: row;
							align-items: flex-start;
							
							.icon {
								margin-right: 12px;
								width: 16px;
								height: 16px;
							}
						}
	
						.description {
							font-size: 12px;
							line-height: 16px;
							margin-top: 4px;
							margin-left: 30px;
						}
					}
				}
			}
		}

		.contact {
			background-color: $color-sand;
			width: 100%;
			padding: 20px 0 30px 0;
			bottom: 0;
			left: 0;
			position: fixed;
			display: flex;
			justify-content: center;
		  
			a {
				width: 90%;
				padding: 15px 28px;
				text-align: center;
				font-family: 'BasisGrotesquePro-Regular';
				font-size: 20px;
				line-height: 24px;
			}
		}
	}

	&__navigation-mobile-language {
		.language-wrapper {
			margin-top: 20px;

			.language-item {
				display: flex;
				flex-direction: row;
				align-items: flex-end;

				p,a {
					color: $color-black;
					font-family: 'BasisGrotesquePro-Regular';
					font-size: 20px;
					margin-top: 24px;
				}
			}
		}
	}
}

.header-shadow {
	box-shadow: 0px 20px 20px 0 rgba(56, 65, 82, 0.04);
}