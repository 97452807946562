.m-hero {
    &__inner__shape {
        padding: 100px 0;
        @media (min-width: 2000px) {
            padding: 150px 0;
        }
        @include media-breakpoint-up(lg) {
            flex-direction: row-reverse;
        }
        @include media-breakpoint-down(sm) {
            padding: 40px 0;
        }
        .primary-wrapper {
            @include media-breakpoint-down(sm) {
                justify-content: unset;
            }
        }
        .primary-content-wrapper {
            &.align-center {
                text-align: center;
                .body, .text {
                    padding: 0 100px;
                    @include media-breakpoint-down(md) {
                        padding: 0 0px;
                    }
                }
                @include media-breakpoint-down(sm) {
                    text-align: left;
                }
            }
            > * {
                margin-top: 30px;
                margin-bottom: 30px;
                @include media-breakpoint-down(sm) {
                    margin-top: 20px;
                    margin-bottom: 20px;
                }
            }
            .title {
                font-family: 'Canela-Regular';
                line-height: 105%;
                letter-spacing: -1.44px;
                margin-top: 0;
                max-width: 440px;
                @media (min-width: 2000px) {
                    max-width: 600px;
                }
                @media (min-width: 1200px) and (max-width: 1400px) {
                    max-width: 385px;
                }
                @include media-breakpoint-down(md) {
                    font-size: 48px;
                    max-width: 100%;
                }
            }
            .body, .pre-title {
                @media (min-width: 2000px) {
                    max-width: 600px;
                }
                @include media-breakpoint-down(sm) {
                    font-size: 20px;
                }
            }
            .text {
                font-size: 20px;
                line-height: 28px;
                max-width: 420px;
                @media (min-width: 2000px) {
                    max-width: 600px;
                }
                @include media-breakpoint-down(md) {
                    max-width: 100%;
                }
                @include media-breakpoint-down(sm) {
                    font-size: 16px;
                    line-height: 22px;
                }
            }
            .link {
                @include media-breakpoint-down(sm) {
                    justify-content: left;
                }
                
            }
            .image {
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .cta-btn-wrapper .cta-btn {
                margin-top: 0;
            }
        }
        .shaped-images-wrapper {
            &__image-image {
                position: relative;
                height: 380px;
                margin-bottom: 50px;
                @include media-breakpoint-down(lg) {
                    height: 500px;
                }
                @include media-breakpoint-down(md) {
                    height: 380px;
                    margin-bottom: 40px;
                }
                @include media-breakpoint-down(sm) {
                    height: 260px;
                    margin-bottom: 30px;
                }
                @media (max-width: 500px) {
                    height: 200px;
                }
                @media (min-width: 1401px) {
                    height: 500px;
                    justify-content: unset;
                }
            }
    
            &__image-color {
                position: relative;
                height: 500px;
                margin-bottom: 50px;
                @include media-breakpoint-up(xl) {
                    padding-right: 100px;
                }
                @include media-breakpoint-down(lg) {
                    transform: translateX(-50px); 
                }
                @include media-breakpoint-down(md) {
                    height: 400px;
                }
                @include media-breakpoint-down(sm) {
                    margin-bottom: 30px;
                    height: 265px;
                    transform: translateX(0);
                }
                @media (max-width: 500px) {
                    height: 220px;
                }
                @media (max-width: 380px) {
                    height: 195px;
                }
            }
        }
    }
}
