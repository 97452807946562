.m-center-headline-intro {

    &.no-padding-bottom {
        .m-center-headline-intro__inner {
            padding-bottom: 0;
        }
    }

    &.double-padding-top {
        .m-center-headline-intro__inner {
            padding-top: 140px;
        }
    }

    &__inner {
        display: block;
        padding: 90px 0 80px 0;

        h2, h4, p {
            text-align: center;
        }

        h2 {
            line-height: rem(58px);
            letter-spacing: -1.12px;
            margin-bottom: 20px;
        }

        h4 {
            margin: 15px 0;
        }

        p {
            color: $color-grey;
            max-width: 840px;
            margin: auto;
        }

        .link-large {
            margin-top: 20px;
        }

        .col-6.col-md-8 {
            padding-right: 10px;
            padding-left: 10px;
        }

        .tag {
            width: fit-content;
            padding: 8px 15px;
            border-radius: 200px;
            margin: 0 auto 20px auto;
            
            &.light-green {
                color: $color-black;
                background-color: $color-light-green;
            }
            &.green {
                color: $color-white;
                background-color: $color-green;
            }
            &.peach {
                color: $color-black;
                background-color: $color-peach;
            }
            &.coral {
                color: $color-white;
                background-color: $color-coral;
            }
            &.stone {
                color: $color-black;
                background-color: $color-stone;
            }
            &.sand {
                color: $color-black;
                background-color: $color-sand;
            }
            &.bright-blue {
                color: $color-white;
                background-color: $color-bright-blue;
            }
            &.aareon-blue {
                color: $color-white;
                background-color: $color-aareon-blue;
            }
        }

        @include media-breakpoint-down(md) {
            h2 {
                line-height: rem(42px);
            }
        }

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    &__inner-mobile {
        display: none;
        padding: 60px 0;

        .button-link-wrapper {
            margin-top: 20px;
        }

        h2 {
            margin: 0;
            + p {
                margin-top: 20px;
            }
            + h4 {
                margin-top: 20px;
            }
        }

        h4 {
            margin-bottom: 20px;
        }

        .tag {
            width: fit-content;
            padding: 8px 15px;
            border-radius: 200px;
            margin: 0 0 20px 0;
            
            &.light-green {
                color: $color-black;
                background-color: $color-light-green;
            }
            &.green {
                color: $color-white;
                background-color: $color-green;
            }
            &.peach {
                color: $color-black;
                background-color: $color-peach;
            }
            &.coral {
                color: $color-white;
                background-color: $color-coral;
            }
            &.stone {
                color: $color-black;
                background-color: $color-stone;
            }
            &.sand {
                color: $color-black;
                background-color: $color-sand;
            }
            &.bright-blue {
                color: $color-white;
                background-color: $color-bright-blue;
            }
            &.aareon-blue {
                color: $color-white;
                background-color: $color-aareon-blue;
            }
        }

        @include media-breakpoint-down(sm) {
            display: block; 
        }
    }
}

h2 {
    font-family: 'Canela-Regular';
    margin-bottom: 10px;
}

.background-white {
    background-color: $color-white;
}

.background-sand {
    background-color: $color-sand;
}
