.m-policy {
    padding: 70px 0 140px;
    @include media-breakpoint-down(sm) {
        padding: 30px 0 70px;
    }
    
    &__inner {
        max-width: 617px;
        margin-left: auto;
        margin-right: auto;
        h1{
            font-family: 'Canela-Regular';
            margin-bottom: 30px;
            @include media-breakpoint-down(md) {
                margin-bottom: 24px;
                font-size: rem(48px);
                line-height: rem(50px);
            }
        }

        h2 {
            font-family: 'BasisGrotesquePro-Medium';
            font-size: rem(24px);
            line-height: rem(26px);
            letter-spacing: -0.48px;
            margin-bottom: 14px;
        }

        .cta-btn {
            display: inline-flex;
            justify-content: center;
            min-width: 170px;
            margin: 0;
        }

        .chapter {
            margin-top: 40px;
            @include media-breakpoint-down(md) {
                margin-top: 30px;
            }
        }
    }

    &__table {
        max-width: 1060px;
        margin-top: 90px;
        margin-bottom: 90px;
        margin-left: auto;
        margin-right: auto;
        overflow-x: scroll;

        tr {
            &:first-of-type {
                border-top: 0;
            }
            &:last-of-type {
                border-bottom: 0;
            }
        }

        td {
            padding: 30px 20px;
            font-size: rem(16px);
            line-height: rem(24px);
            color: $color-grey;
            min-width: 200px;
            font-family: 'BasisGrotesquePro-Regular';
            &:first-of-type {
                padding-left: 0;
            }
            &:last-of-type {
                padding-right: 0;
            }
            &.table-heading {
                font-size: rem(20px);
                line-height: rem(22px);
                color: $color-black;
                vertical-align: bottom;
                font-family: 'BasisGrotesquePro-Bold';
                @include media-breakpoint-down(lg) {
                    font-size: rem(16px);
                    line-height: rem(24px);
                }
            }
            @include media-breakpoint-down(lg) {
                min-width: 0;
            }
        }
    }
}