@mixin h1() {
	font-family: 'BasisGrotesquePro-Regular';
	font-size: rem(72px);
	line-height: rem(76px);
	font-weight: $font-regular;
	color: $color-black;
	hyphens: auto;

	a{
		color: $color-bright-blue;
		transition: all 0.4s ease;
		
		&:hover{
			color: $color-aareon-blue;
			text-decoration: none;
		}
	}

	&.headline-xl{
		font-size: rem(72px);
		line-height: rem(76px);
		font-weight: $font-regular
	}

	@media (min-width: 1199.98px) and (max-width: 1400px){
		font-size: rem(58px);
		line-height: rem(68px);
		hyphens: initial;
		
		&.headline-xl{
			font-size: rem(60px);
			line-height: rem(63px);
		}
	}

	@include media-breakpoint-down(lg) {
		font-size: rem(52px);
		line-height: rem(62px);
		hyphens: initial;

		&.headline-xl{
			font-size: rem(60px);
			line-height: rem(63px);
		}
	}

	@include media-breakpoint-down(md) {
		font-size: rem(32px);
		line-height: rem(44px);
		hyphens: initial;

		&.headline-xl{
			font-size: rem(32px);
			line-height: rem(44px);
		}
	}

	@include media-breakpoint-down(sm) {
		font-size: rem(28px);
		line-height: rem(36px);
		hyphens: initial;

		&.headline-xl{
			font-size: rem(28px);
			line-height: rem(36px);
		}
	}
}

h1,
.m-h1 {
	@include h1;
}

@mixin h2() {
	font-family: 'BasisGrotesquePro-Regular';
	font-size: rem(56px);
	line-height: rem(58px);
	font-weight: $font-regular;
	color: $color-black;
	margin-bottom: 6px;

	a{
		color: $color-bright-blue;
		transition: all 0.4s ease;
		
		&:hover{
			color: $color-aareon-blue;
			text-decoration: none;
		}
	}

	&.headline-m{
		font-size: rem(28px);
		line-height: rem(36px);
	}

	&.headline-xl{
		font-size: rem(72px);
		line-height: rem(76px);
		font-weight: $font-regular;
	}

	@include media-breakpoint-down(md) {
		font-size: rem(38px);
		line-height: rem(42px);

		&.headline-m{
			font-size: rem(28px);
			line-height: rem(36px);
		}

		&.headline-xl{
			font-size: rem(28px);
			line-height: rem(36px);
		}
	}
}

h2,
.m-h2 {
	@include h2;
}

@mixin h3() {
	font-family: 'BasisGrotesquePro-Regular';
	font-size: rem(28px);
	line-height: rem(36px);
	font-weight: $font-medium;
	color: $color-black;
	margin-bottom: 8px;

	a {
		color: $color-bright-blue;
		transition: all 0.4s ease;
		
		&:hover{
			color: $color-bright-blue;
			text-decoration: none;
		}
	}

	&.headline-m{
		font-size: rem(28px);
		line-height: rem(36px);
	}

	&.headline-s{
		font-size: rem(24px);
		line-height: rem(29px);
	}

	@include media-breakpoint-down(md) {
		font-size: rem(20px);
		line-height: rem(24px);

		&.headline-m{
			font-size: rem(20px);
			line-height: rem(24px);
		}
	
		&.headline-s{
			font-size: rem(20px);
			line-height: rem(24px);
		}
	}
}

h3,
.m-h3 {
	@include h3;
}

@mixin h4() {
	font-family: 'BasisGrotesquePro-Regular';
	font-size: rem(24px);
	line-height: rem(26px);
	color: $color-black;
	font-weight: $font-medium;

	a{
		color: $color-bright-blue;
		
		&:hover{
			color: $color-bright-blue;
		}
	}

	@include media-breakpoint-down(md) {
		font-size: rem(24px);
		line-height: rem(26px);
	}
}

h4,
.m-h4 {
	@include h4;
}

@mixin p() {
	font-family: 'BasisGrotesquePro-Regular';
	font-size: rem(18px);
	line-height: rem(27px);
	color: $color-grey;
	font-weight: $font-regular;

	i {
		font-style: italic;
	}

	b,
	strong {
		font-family: 'BasisGrotesquePro-Bold';
	}

	em{
		font-style: italic;
	}

	a{
		transition: all 0.4s ease;
		color: $color-bright-blue;

		&:hover {
			text-decoration: none;
		}
	}

	&.body-copy-s{
		font-size: rem(14px);
		line-height: rem(20px);
		color: $color-grey;
		font-weight: $font-regular;

		+ ul{
			li{
				font-size: rem(14px);
				line-height: rem(20px);
			}
		}

		+ ol{
			li{
				font-size: rem(14px);
				line-height: rem(20px);
			}
		}
	}

	&.body-copy-m{
		font-size: rem(16px);
		line-height: rem(24px);
		color: $color-grey;
		font-weight: $font-regular;

		+ ul{
			li{
				font-size: rem(16px);
				line-height: rem(24px);
			}
		}

		+ ol{
			li{
				font-size: rem(16px);
				line-height: rem(24px);
			}
		}
	}

	&.body-copy-l{
		font-size: rem(20px);
		line-height: rem(28px);
		color: $color-grey;
		font-weight: $font-regular;
	}

	&.body-copy-xl{
		font-size: rem(24px);
		line-height: rem(36px);
		color: $color-grey;
		font-weight: $font-regular;

		&--black{
			font-weight: $font-regular;
			color: $color-black;
		}

		+ ul{
			li{
				font-size: rem(24px);
				line-height: rem(36px);
			}
		}

		+ ol{
			li{
				font-size: rem(24px);
				line-height: rem(36px);
			}
		}
	}

	&.headline-xl{
		font-size: rem(60px);
		line-height: rem(63px);
		font-weight: $font-regular;

		+ p{
			margin-top: 0
		}
	}

	@include media-breakpoint-down(md) {
		font-size: rem(16px);
		line-height: rem(22px);

		&.body-copy-l{
			font-size: rem(16px);
			line-height: rem(22px);
		}
	
		&.body-copy-xl{
			font-size: rem(16px);
			line-height: rem(24px);
		}

		&.headline-xl{
			font-size: rem(32px);
			line-height: rem(44px);
		}
	}
}

p,
.m-p {
	@include p;

	+ p,
	.m-p {
		margin-top: rem(24px);
	}
}

.preheader{
	font-size: rem(18px);
	line-height: rem(27px);
	margin-bottom: 20px;
	color: $color-grey;
	font-weight: $font-regular;
	display: inline-flex;

	a{
		color: $color-grey;
		
		&:hover{
			color: $color-grey;
		}
	}

	@include media-breakpoint-down(md) {
		font-size: rem(15px);
		line-height: rem(22px);
		margin-bottom: 10px;
		display: block;
	}
}

p {
	+ ul {
		list-style-type: disc;
		padding-left: 20px;
		margin-top: 20px;
		margin-bottom: 20px;
		margin-left: 0;

		li{
			font-size: rem(18px);
			line-height: rem(27px);
			color: $color-grey;
			font-weight: $font-regular;

			a{
				transition: all 0.4s ease;
				color: $color-bright-blue;

				&:hover {
					color: $color-aareon-blue;
					text-decoration: none;
				}
			}
		}

		@include media-breakpoint-down(md) {
			padding-left: 20px;
			margin-top: 20px;

			li{
				font-size: rem(15px);
				line-height: rem(22px);
			}
		}
	}

	+ ol {
		list-style-type: decimal;
		padding-left: 20px;
		margin-top: 20px;
		margin-bottom: 20px;
		color: $color-grey;
		font-weight: $font-regular;

		li{
			font-size: rem(18px);
			line-height: rem(27px);
			color: $color-grey;
			font-weight: $font-regular;
		}

		@include media-breakpoint-down(md) {
			padding-left: 20px;
			margin-top: 20px;

			li{
				font-size: rem(15px);
				line-height: rem(22px);
			}
		}
	}
}

ul {
	list-style: disc;
	margin-left: 20px;
}

.body-copy-m p, .body-copy-m{
	font-size: rem(16px);
	line-height: rem(24px);
	color: $color-grey;
	font-weight: $font-regular;

	+ ul{
		li{
			font-size: rem(16px);
			line-height: rem(24px);
		}
	}

	+ ol{
		li{
			font-size: rem(16px);
			line-height: rem(24px);
		}
	}

	@include media-breakpoint-down(md) {
		font-size: rem(15px);
		line-height: rem(22px);

		+ ul{
			li{
				font-size: rem(15px);
				line-height: rem(22px);
			}
		}
	
		+ ol{
			li{
				font-size: rem(15px);
				line-height: rem(22px);
			}
		}
	}
}

.body-copy-l p, .body-copy-l{
	font-size: rem(18px);
	line-height: rem(27px);
	color: $color-grey;
	font-weight: $font-regular;

	+ ul{
		li{
			font-size: rem(18px);
			line-height: rem(27px);
		}
	}

	+ ol{
		li{
			font-size: rem(18px);
			line-height: rem(27px);
		}
	}
	
	@include media-breakpoint-down(md) {
		font-size: rem(15px);
		line-height: rem(22px);

		+ ul{
			li{
				font-size: rem(15px);
				line-height: rem(22px);
			}
		}
	
		+ ol{
			li{
				font-size: rem(15px);
				line-height: rem(22px);
			}
		}
	}
}
