.m-two-col-img-headline-intro {

    &.double-padding-top {
        .m-two-col-img-headline-intro__inner {
            padding-top: 140px;
            @include media-breakpoint-down(md) {
                padding-top: 70px;
            }
        }
    }

    .col, .row {
        margin: 0;
        padding: 0;
    }
    
    &__inner {
        padding: 100px 0;
        
        @media (min-width: 2000px) {
            max-width: 1600px;
            margin: auto;
        }

        @include media-breakpoint-down(md) {
            padding: 50px 0;
        }

        &--content {
            @include media-breakpoint-down(sm) {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;

                h2{
                    margin-bottom: 20px;
                }
            }
        }

        .media-wrapper {
            display: block;
            @include media-breakpoint-down(md) {
                display: none;
            }

            .image, .masked-image {
                width: 590px;
                object-fit: contain;

                @include media-breakpoint-down(lg) {
                    width: 450px;
                }
            }
            
            .masked-image {
                @media (min-width: 2000px) {
                    width: 100%;
                }
                @media (max-width: 1330px) {
                    justify-content: flex-start;
                    width: 100%;
                }
            }
        }

        .media-wrapper-mobile {
            display: none;
            @include media-breakpoint-down(md) {
                display: flex;
                width: 400px;
                justify-content: flex-start;
            }

            @include media-breakpoint-down(sm) {
                width: 100%;
            }
            
            .image {
                width: 100%;
                margin-bottom: 30px;
            }
            
            .masked-image {
                margin-bottom: 80px;
                justify-content: flex-start;
            }
        }

        .content-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            
            ul {
                list-style: none;
                padding-left: 0;
                margin-top: 28px;
                margin-left: 0;
                margin-bottom: 0;
                max-width: 620px;

                li {
                    font-family: 'BasisGrotesquePro-Medium';
                    color: $color-black;
                    margin-bottom: 10px;
                    padding-left: 32px;
                    position: relative;
                    font-size: 16px;
                    line-height: 22px;

                    &::before {
                        content: ''; 
                        position: absolute;
                        left: 0;
                        top: 11px; 
                        transform: translateY(-50%); 
                        width: 14px; 
                        height: 14px; 
                        background-image: url('bullet-point.svg');
                        background-size: cover; 
                    }
                }
            }

            .tag {
                width: fit-content;
                padding: 8px 15px;
                border-radius: 200px;
                margin-bottom: 28px;

                @include media-breakpoint-down(md) {
                    margin-bottom: 20px;
                }
                
                &.light-green {
                    color: $color-black;
                    background-color: $color-light-green;
                }
                &.green {
                    color: $color-white;
                    background-color: $color-green;
                }
                &.peach {
                    color: $color-black;
                    background-color: $color-peach;
                }
                &.coral {
                    color: $color-white;
                    background-color: $color-coral;
                }
                &.stone {
                    color: $color-black;
                    background-color: $color-stone;
                }
                &.sand {
                    color: $color-black;
                    background-color: $color-sand;
                }
                &.bright-blue {
                    color: $color-white;
                    background-color: $color-bright-blue;
                }
                &.aareon-blue {
                    color: $color-white;
                    background-color: $color-aareon-blue;
                }
            }

            .title {
                max-width: 620px;
                @include media-breakpoint-up(lg) {
                    font-size: 56px;
                    line-height: 60px;
                }
            }

            .title-small {
                max-width: 620px;
                @include media-breakpoint-up(lg) {
                    font-size: 40px;
                    line-height: 44px;
                    margin-bottom: 24px;
                }
            }

            .sub-title {
                max-width: 620px;
                font-family: 'BasisGrotesquePro-Medium';
                font-size: 24px;
                @include media-breakpoint-down(md) {
                    font-size: 20px;
                }
            }

            .large {
                max-width: 620px;
                .link {
                    a {
                        display: flex;
                        align-items: center;
                        font-size: rem(20px);
                        line-height: rem(28px);
                        font-family: 'BasisGrotesquePro-Bold';
                    }
                    img {
                        margin-left: 5px;
                        width: 20px;
                        height: 20px;
                    }
                    @include media-breakpoint-down(md) {
                        a {
                            font-size: rem(16px);
                            line-height: rem(24px);
                        }
                    }
                }
            }

            .small {
                max-width: 620px;
                font-size: 16px;
                line-height: 22px;
            }

            + .media-wrapper {
                .image.simple {
                    width: 100%;
                }
            }
        }

        .right {
            padding-right: 200px;
            @include media-breakpoint-down(md) {
                padding-right: 0;
                padding-left: 80px;
            }
            @include media-breakpoint-down(sm) {
                padding-left: 0;
            }
        }
        
        .left {
            padding-left: 80px;
            @media (min-width: 2000px) {
                max-width: 700px;
            }
            @include media-breakpoint-down(sm) {
                padding-left: 0;
            }
        }       
    }
}