//classes
.u-scroll-lock {
  overflow: hidden;
}

.p-0{
  padding: 0;
}

.pl-0{
  padding-left: 0;
}

.pr-0{
  padding-right: 0;
}

.justify-content-center{
  justify-content: center;
}

.justify-content-between{
  justify-content: space-between;
}

.justify-content-end{
  justify-content: flex-end;
}

.align-items-center{
  align-items: center;
}

.align-items-end{
  align-items: flex-end;
}

//transitions
$transition: 0.3s ease;
$color-transition: color $transition;
$background-transition: background $transition;
$button-transition: $color-transition, $background-transition;
$scale-transition: transform 2s cubic-bezier(0.25, 0.46, 0.45, 0.94),
-webkit-transform 2s cubic-bezier(0.25, 0.46, 0.45, 0.94);

// grid

@media (min-width: 2000px) {
  .container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    width: 100%;
    max-width: 2100px;
    padding-right: 80px;
    padding-left: 80px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 1200px) {
  .container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    width: 100%;
    padding-right: 80px;
    padding-left: 80px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 992px) and (max-width: 1200px){
  .container, .container-sm, .container-md, .container-lg {
    max-width: 100%;
  }
  .container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl{
    padding-right: 50px;
    padding-left: 50px;
  }
}

@media (min-width: 720px) and (max-width: 991px){
  .container, .container-sm, .container-md {
    max-width: 100%;
  }
  .container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl{
    padding-right: 50px;
    padding-left: 50px;
  }
}

@media (min-width: 540px) and (max-width: 719px){
  .container, .container-sm {
    max-width: 100%;
  }
  .container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl{
    padding-right: 25px;
    padding-left: 25px;
  }
}

@media (min-width: 0) and (max-width: 540px) {
  .row > .col, .row > [class*="col-"] {
    padding-right: 25px;
    padding-left: 25px;
  }
}

// background
.bg-white {
  background-color: $color-white !important;
}

