.m-contact {
    padding: 80px 0 100px;
    @include media-breakpoint-down(sm) {
        padding: 30px 0 70px;
    }
    
    &__inner {
        max-width: 532px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        @include media-breakpoint-down(sm) {
            text-align: left;
        }
       
        h1{
            font-family: 'Canela-Regular';
            margin-bottom: 30px;
            @include media-breakpoint-down(md) {
                margin-bottom: 24px;
                font-size: rem(48px);
                line-height: rem(50px);
            }
        }
    }

    &__form {
        max-width: 620px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 60px;
        @include media-breakpoint-down(md) {
            margin-top: 30px;
        }
    }
}