#layout {
	&::before{
		content: '';
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.1);
		position: fixed;
		z-index: 1;
		display: none;
	}

	&.overlay{
		&:before{
			display: block;
		}
	}
}

