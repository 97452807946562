body {
	background-color: $color-white;
	font-family: 'BasisGrotesquePro-Regular';
	font-size: 1rem;
	color: $color-grey;
	margin-top: rem(96px);
	max-width: 100%;
	overflow-x: hidden;
	
	.body-overlay {
		display: none;
		position: fixed;
		inset: 0;
		background: rgba(0, 0, 0, 0.2);
		z-index: 10;
		opacity: 0;
		transition: opacity 1.5s ease;

		&.visible {
			display: block;
		  	opacity: 1;
		}
	}

	button {
		background: $color-bright-blue;
		color: $color-white;
		border-radius: 200px;
		padding: 16px 24px;
		border: 0;
		margin-top: $btn-margin-top;
		transition: all .2s ease;

		&:hover {
			background-color: $color-btn-hover;
		}
	}

	table {
		tr {
			border-bottom: 1px solid #cccccc;
			
			&:first-of-type{
				border-top: 1px solid #cccccc;
			}

			td{
				line-height: 1.5rem;
    			padding: 10px 0;
			}
		}
	}

	a {
		font-family: 'BasisGrotesquePro-Medium';
		transition: all .2s ease;
	}  
    
}
