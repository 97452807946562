.m-hero {
    &__inner__widget {
        padding: 60px 0;
        @include media-breakpoint-up(lg) {
            flex-direction: row-reverse;
            height: 100%;
        }
        @include media-breakpoint-down(sm) {
            padding: 30px 0;
        }
        .primary-wrapper {
            @media (min-width: 1680px) {
               align-items: center;
            }
            @include media-breakpoint-down(sm) {
                justify-content: unset;
            }
        }
        .primary-content-wrapper {
            @media (min-width: 1680px) {
                max-width: 600px;
            }

            &.align-center {
                text-align: center;
                .body, .text {
                    padding: 0 100px;
                    @include media-breakpoint-down(md) {
                        padding: 0 0px;
                    }
                }
                @include media-breakpoint-down(sm) {
                    text-align: left;
                }
            }
            > * {
                margin-top: 30px;
                margin-bottom: 30px;
                @include media-breakpoint-down(sm) {
                    margin-top: 20px;
                    margin-bottom: 20px;

                    &:first-child {
                        margin-top: 0px;
                    }
                }
            }
            .title {
                font-family: 'Canela-Regular';
                line-height: 105%;
                @include media-breakpoint-down(md) {
                    font-size: 48px;
                }
            }
            .body, .pre-title {
                @include media-breakpoint-down(sm) {
                    font-size: 20px;
                }
            }
            .text {
                font-size: 20px;
                @include media-breakpoint-down(sm) {
                    font-size: 16px;
                }
            }
            .link {
                @include media-breakpoint-down(sm) {
                    justify-content: left;
                }
                
            }
            .image {
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .cta-btn-wrapper {
                .cta-btn {
                    margin-top: 0;
                }
            }
        }

        .widget-images-wrapper {
            &__aside {
                position: relative;
                height: 500px;
                width: 100%;
                margin-bottom: 20px;
                @include media-breakpoint-down(lg) {
                    height: 400px;
                }
                @include media-breakpoint-down(lg) {
                    height: 400px;
                }
                @include media-breakpoint-down(md) {
                    height: 300px;
                }
                @include media-breakpoint-down(sm) {
                    margin-bottom: 10px;
                }
                img {
                    object-fit: contain;
                    aspect-ratio: 1/1;
                    @include media-breakpoint-down(sm) {
                        object-fit: cover;
                    }
                }
            }
        }

    }
    &.widget {
        &__aside {
            max-width: 1650px;
            margin: auto;
            flex-direction: row;
            @media (min-width: 2000px) {
                max-width: 2100px;
            }
            @media (min-width: 1680px) {
                padding-right: 80px;
            }
            @include media-breakpoint-down(md) {
                flex-direction: column-reverse;
            }
        }
        &__below {
            flex-direction: column;
            @include media-breakpoint-down(sm) {
                flex-direction: column-reverse;
            }
            .primary-content-wrapper {
                max-width: 1240px;
            }
        }
        .widget-images-full-width-container {
            &__aside {
                margin-top: 60px;
                margin-bottom: 80px;
                @include media-breakpoint-down(md) {
                    margin-top: 40px;
                    margin-bottom: 0;
                }
                .widget-images-full-width-wrapper {
                    width: 100%;
                    overflow: hidden;
                    @media (min-width: 1680px) {
                        width: 120%;
                    }
                    @include media-breakpoint-down(sm) {
                        padding-left: 20px;
                        padding-right: 20px;
                    }
                    img{
                        width: 125%;
                        margin-left: 23%;
                        object-fit: cover;
                        @-moz-document url-prefix() {
                            margin-left: 0;
                          }
                        @media (min-width: 1680px) {
                            width: 100%;
                            margin-left: 0;
                        }
                        @media (max-width: 1280px) {
                            width: 100%;
                            margin-left: 0;
                        }
                        @include media-breakpoint-down(md) {
                            max-width: 80%;
                            margin: auto;
                        }
                        @include media-breakpoint-down(sm) {
                            max-width: 100%;
                        }
                    }
                }
            }
            &__below {
                overflow-x: hidden;
                margin-bottom: 80px;
                @include media-breakpoint-down(sm) {
                    margin-top: 30px;
                    margin-bottom: 0;
                }
                .widget-images-full-width-wrapper {
                    width: fit-content;
                    height: auto;
                    overflow: hidden;
                    margin: auto;
                    img{
                        width: 100%;
                        height: auto;
                        object-fit: cover;
                    }
                    @include media-breakpoint-down(sm) {
                        width: 100%;
                    }
                    &.full-bleed {
                        width: 110%;
                        margin-left: -5%;
                        @media (min-width: 1681px) {
                            width: 100%;
                            margin-left: auto;
                            margin-right: auto;
                            max-width: 1680px;
                        }
                        @include media-breakpoint-down(sm) {
                            img {
                                width: 200%;
                                @-moz-document url-prefix() {
                                    height: 250px;
                                  }
                            }
                        }       
                    }
                }
            }
        }
    }
}