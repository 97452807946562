.m-logos {
	overflow-x: hidden;
	
	@keyframes scrolling-logos {
		from {
			transform: translateX(0);
		}
		to {
			transform: translateX(-100% );
		}
	}
	
	&__logos {
        display: flex;
		flex-direction: row;
		width: 100%;

		.logos-row {
			animation: scrolling-logos 40s linear infinite; // Adjust number to increase or decrease speed of animation
			animation-fill-mode: both;

			&.reverse {
				animation-direction: reverse;
			}
		}

		.logos-row {
			display: flex;

			.logos-wrapper {

				.logo {
					width: 180px;
					height: 180px;
					margin: auto;
					border: 1px solid #CED0D4;
					border-radius: 40px;
					padding: 25px;
					margin: 15px;
					display: flex;
					justify-content: center;
					align-items: center;
					position: relative;
					overflow: hidden;

					@include media-breakpoint-down(md) {
						width: 136px;
						height: 136px;
						border-radius: 30px;
						padding: 20px;
						margin: 12px;
					}

					img{
						width: 100%;
						height: auto;
					}

					span {
						color: $color-grey;
						text-align: center;
						font-size: rem(12px);
						line-height: rem(16px);
						opacity: 0.6;
						position: absolute;
						bottom: 14px;
						left: 0;
						right: 0;
						@include media-breakpoint-down(md) {
							bottom: 10px;
						}
					}
				}
			}
		}
	}
}

.m-partners {
	padding-top: 24px;
	padding-bottom: 154px;
	@include media-breakpoint-down(md) {
		padding-bottom: 64px;
	}
	.logos-row, .logos-row-reverse {
		.logos-wrapper{
			.logo{
				padding: 0;
			}
		}
	}
}

.m-locations {
	padding-top: 70px;
	padding-bottom: 70px;
	@include media-breakpoint-down(md) {
		padding-top: 60px;
		padding-bottom: 60px;
	}
}