.m-usp-large {
    border-radius: 16px;
    flex-shrink: 0;
    &.usp-col-2 {
        width: 100%;
        height: auto;
        min-height: 350px;
        @include media-breakpoint-down(md) {
            height: auto;
            min-height: 0;
        }
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
        .wrapper{
            margin: 0;
            .image-wrapper {
                padding: 0px;
                height: 120px;
                @include media-breakpoint-down(sm) {
                    width: 40px;
                    height: 80px;
                }
                img {
                    width: 80px;
                    height: 80px;
                    position: absolute;
                    left: 40px;
                    top: 40px;
                    @include media-breakpoint-down(sm) {
                        width: 40px;
                        height: 40px;
                        left: 20px;
                    }
                }
            }
            .text-wrapper {
                display: flex;
                flex-direction: column;
                align-self: start;
                padding: 40px 40px 54px 40px;
                @include media-breakpoint-down(lg) {
                    align-self: start;
                }
                @include media-breakpoint-down(sm) {
                    padding: 10px 20px 40px;
                }
                .usp-bulletlist-ul, ul {
                    margin-left: 20px;
                    list-style: disc;
                }
                h3 {
                    font-family: 'Canela-Regular';
                    font-size: 40px;
                    line-height: 42px;
                    margin-bottom: 15px;
                    @include media-breakpoint-down(sm) {
                        font-size: 30px;
                    }
                }
                h4 {
                    margin-bottom: 15px;
                    letter-spacing: -0.48px;
                }
                p {
                    font-size: 16px;
                }
                .usp-bulletlist-li, li {
                    font-size: 16px;
                    line-height: 22px;
                    color: #384152;
                }
            }
        }
    }
}