.m-headline-text-image {
    padding: 60px 0 20px;
    @include media-breakpoint-down(sm) {
        padding: 30px 0 20px;
    }
    &__inner {
        .wrapper {
            padding-bottom: 100px;
            @include media-breakpoint-down(sm) {
                padding-bottom: 50px;
            }
            .row {
                @include media-breakpoint-up(md) {
                    justify-content: center;
                }
            }
            h4 {
                margin-bottom: 20px;
            }
            p, a {
                font-size: 16px;
            }
            .image-wrapper {
                @include media-breakpoint-down(sm) {
                    padding-right: 25px;
                    padding-left: 25px;
                }
                margin-bottom: 30px;
                img {
                    width: 150px;
                    @media (min-width: 2000px) {
                        width: 200px;
                    }
                }
            }
            .text-wrapper {
                width: 400px;
                @media (min-width: 2000px) {
                    max-width: 550px;
                }
            }
        }
    }
}